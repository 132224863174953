import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../../components/Modal'
import { QuestionCircle, XLg } from 'react-bootstrap-icons'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.text2};
  font-size: 1.5rem;
  margin: 0;
`

const HelpIcon = styled(QuestionCircle)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const InstructionModal = styled(Modal)`
  width: 90%;
  max-width: 600px;
`

const ModalContent = styled.div`
  padding: 1.5rem;
  text-align: center;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.text1};
  &:hover {
    opacity: 0.7;
  }
`

const InstructionTitle = styled.h3`
  color: ${({ theme }) => theme.primary1};
  margin-bottom: 1rem;
`

const InstructionList = styled.ol`
  text-align: left;
  padding-left: 1.5rem;
`

const InstructionItem = styled.li`
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.text1};
`

const SubInstruction = styled.p`
  margin-left: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
`

function SwapTitle() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <TitleWrapper>
      <Title>Bulk Swap</Title>
      <HelpIcon size={20} onClick={() => setIsModalOpen(true)} />
      <InstructionModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)}>
        <ModalContent>
          <ModalHeader>
            <InstructionTitle>使用说明</InstructionTitle>
            <CloseButton onClick={() => setIsModalOpen(false)}>
              <XLg size={18} />
            </CloseButton>
          </ModalHeader>
          <InstructionList>
          <InstructionItem>连接SWAP使用的网络需要与您要进行批量交易的网络一致</InstructionItem>
          <SubInstruction>- 目前支持 ETH/BSC 根据连接自动切换</SubInstruction>
            <InstructionItem>选择要交易的代币对。</InstructionItem>
            <InstructionItem>导入要进行批量交易的钱包地址。</InstructionItem>
            <SubInstruction>- 可以进行全选/或单选需要交易的钱包</SubInstruction>
            <SubInstruction>- 使用固定值买入钱包里余额的百分比/或者完全随机化金额皆可</SubInstruction>
            <InstructionItem>调整滑点和GAS设置。</InstructionItem>
            <SubInstruction>- GAS增加建议保持默认10%保证建议成功率，滑点请根据您的情况设定即可</SubInstruction>
            <InstructionItem>点击"批量兑换"按钮开始交易。</InstructionItem>
          </InstructionList>
        </ModalContent>
      </InstructionModal>
    </TitleWrapper>
  )
}

export default SwapTitle