// TargetVolumeModal.tsx
import React, { useState, useEffect } from 'react'
import Confetti from 'react-confetti'
import styled from 'styled-components'
import { X, RefreshCw } from 'react-feather'

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  z-index: 50;
`

const Card = styled.div`
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #9CA3AF;

  &:hover {
    color: #4B5563;
  }
`

const CardContent = styled.div`
  padding: 1.5rem;
`

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const Title = styled.h2`
  font-size: 1.875rem;
  font-weight: 800;
  color: #1F2937;
  margin-bottom: 0.5rem;
`

const StatsContainer = styled.div`
  background: #F9FAFB;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 1.5rem;
`

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const StatLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: #4B5563;
`

const CurrentValue = styled.span`
  font-size: 1.875rem;
  font-weight: 700;
  color: #2563EB;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.7; }
  }
`

const TargetValue = styled.span`
  font-size: 1.875rem;
  font-weight: 700;
  color: #059669;
`

const RefreshHint = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`

const HintText = styled.p`
  font-size: 0.875rem;
  color: #6B7280;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseModalButton = styled.button`
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #D1D5DB;
  border-radius: 0.375rem;
  color: #1F2937;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #F9FAFB;
  }
`

interface TargetVolumeModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentTradingVolume: number;
  targetVolume: number;
}

export default function TargetVolumeModal({
  isOpen,
  onClose,
  currentTradingVolume,
  targetVolume
}: TargetVolumeModalProps) {
  const [showConfetti, setShowConfetti] = useState(false)
  
  useEffect(() => {
    if (isOpen) {
      setShowConfetti(true)
      const timer = setTimeout(() => setShowConfetti(false), 5000)
      return () => clearTimeout(timer) // 返回清理函数
    }
    return () => {} // 添加空的清理函数作为默认返回值
  }, [isOpen])

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} recycle={false} />}
      <Card>
        <CloseButton onClick={onClose}>
          <X size={16} />
        </CloseButton>
        <CardContent>
          <HeaderContainer>
            <Title>🎉 已达成目标值！ 🎉</Title>
          </HeaderContainer>
          <StatsContainer>
            <StatRow>
              <StatLabel>已达成交易量</StatLabel>
              <CurrentValue>{currentTradingVolume}</CurrentValue>
            </StatRow>
            <StatRow>
              <StatLabel>目标交易量</StatLabel>
              <TargetValue>{targetVolume}</TargetValue>
            </StatRow>
          </StatsContainer>
          <RefreshHint>
            <HintText>
              <RefreshCw size={16} style={{ marginRight: '0.5rem' }} />
              如需继续进行市值管理，请刷新浏览器后重新开始
            </HintText>
          </RefreshHint>
          <CloseModalButton onClick={onClose}>
            关闭
          </CloseModalButton>
        </CardContent>
      </Card>
    </ModalOverlay>
  )
}