import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../../components/Modal'
import { QuestionCircle, XLg } from 'react-bootstrap-icons'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.text2};
  font-size: 1.5rem;
  margin: 0;
`

const HelpIcon = styled(QuestionCircle)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${({ theme }) => theme.primary1};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const InstructionModal = styled(Modal)`
  width: 90%;
  max-width: 700px;
`

const ModalContent = styled.div`
  padding: 2rem;
  text-align: left;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.primary1};
  margin-bottom: 1.5rem;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.text1};
  &:hover {
    opacity: 0.7;
  }
`

const InstructionTitle = styled.h3`
  color: ${({ theme }) => theme.primary1};
  margin: 0;
  font-size: 1.5rem;
`

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.primary1};
  margin: 1.5rem 0 1rem;
  font-size: 1.2rem;
  border-left: 4px solid ${({ theme }) => theme.primary1};
  padding-left: 1rem;
`

const InstructionList = styled.ol`
  padding-left: 1.5rem;
  margin: 0;
`

const InstructionItem = styled.li`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
`

const ImportantNote = styled.div`
  background: ${({ theme }) => `${theme.primary1}11`};
  border-left: 4px solid ${({ theme }) => theme.primary1};
  padding: 1rem;
  margin: 1rem 0;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
`

const SubInstruction = styled.p`
  margin: 0.5rem 0 0.5rem 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text3};
  line-height: 1.4;
`

function MarketMakingTitle() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <TitleWrapper>
      <Title>市值管理</Title>
      <HelpIcon size={20} onClick={() => setIsModalOpen(true)} />
      <InstructionModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)}>
        <ModalContent>
          <ModalHeader>
            <InstructionTitle>市值管理使用说明</InstructionTitle>
            <CloseButton onClick={() => setIsModalOpen(false)}>
              <XLg size={18} />
            </CloseButton>
          </ModalHeader>

          <ImportantNote>
            BOT使用本地储存模式，任何私钥等信息仅储存在您浏览器缓存中，不会上传至服务器，因此在执行过程中，请勿刷新网页或关闭浏览器。
          </ImportantNote>

          <SectionTitle>钱包配置</SectionTitle>
          <InstructionList>
            <InstructionItem>导入A钱包（买入资金钱包）</InstructionItem>
            <SubInstruction>- 此钱包需要存放用于市值管理的买入资金</SubInstruction>
            
            <InstructionItem>导入B钱包（GAS费用钱包）</InstructionItem>
            <SubInstruction>- 此钱包需要存放用于卖出交易的GAS费用</SubInstruction>
          </InstructionList>

          <SectionTitle>交易配置</SectionTitle>
          <InstructionList>
            <InstructionItem>选择交易网络和代币</InstructionItem>
            <SubInstruction>- 交易网络根据您连接的网络自动调整</SubInstruction>
            <SubInstruction>- 选择 BNB/ETH 原生代币</SubInstruction>
            <SubInstruction>- 选择需要进行市值管理的代币</SubInstruction>

            <InstructionItem>交易参数设置</InstructionItem>
            <SubInstruction>- 滑点设置：建议保持在 15% 以上</SubInstruction>
            <SubInstruction>- GAS增量：建议设置 5% 或以上以保证交易速率</SubInstruction>

            <InstructionItem>交易比例配置</InstructionItem>
            <SubInstruction>- 买入比例设置范围：10-95%</SubInstruction>
            <SubInstruction>- 卖出比例设置范围：10-100%</SubInstruction>

            <InstructionItem>设定目标/间隔</InstructionItem>
            <SubInstruction>- 在达到预期交易量后自动停止交易</SubInstruction>
            <SubInstruction>- 设定每组钱包之间交易间隔/秒</SubInstruction>
          </InstructionList>

          <ImportantNote>
            本BOT市值逻辑：钱包A买入代币 → 钱包B接收代币 → 钱包B卖出代币 → 钱包A接收卖出的代币费用，每一组钱包搭配跟您导入时的循序相同。
          </ImportantNote>
        </ModalContent>
      </InstructionModal>
    </TitleWrapper>
  )
}

export default MarketMakingTitle