import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { debounce } from 'lodash';

interface TokenApprovalProps {
  tokenAddress: string;
  spenderAddress: string;
  amount: string;
  onApprovalSuccess: () => void;
  tokenDecimals: number;
  isNativeCoin: boolean;
  isInputValid: boolean;
  renderButton: (onClick: () => void, disabled: boolean, text: string) => React.ReactElement;
}

const TokenApproval: React.FC<TokenApprovalProps> = ({
  tokenAddress,
  spenderAddress,
  amount,
  onApprovalSuccess,
  tokenDecimals,
  isNativeCoin,
  isInputValid,
  renderButton
}) => {
  const { account, library } = useWeb3React();
  const [currentAllowance, setCurrentAllowance] = useState(ethers.BigNumber.from(0));
  const [isApproving, setIsApproving] = useState(false);

  const checkAllowance = useCallback(
    debounce(async () => {
      if (isNativeCoin || !account || !library || !tokenAddress || !isInputValid) {
        setCurrentAllowance(ethers.BigNumber.from(0));
        return;
      }

      const tokenContract = new ethers.Contract(
        tokenAddress,
        ['function allowance(address owner, address spender) view returns (uint256)'],
        library.getSigner()
      );

      try {
        const allowance = await tokenContract.allowance(account, spenderAddress);
        setCurrentAllowance(allowance);
      } catch (error) {
        console.error('Error checking allowance:', error);
        setCurrentAllowance(ethers.BigNumber.from(0));
      }
    }, 500),
    [account, library, tokenAddress, spenderAddress, isNativeCoin, isInputValid]
  );

  useEffect(() => {
    checkAllowance();
  }, [checkAllowance]);

  const handleApprove = async () => {
    if (isNativeCoin || !account || !library || !tokenAddress || !amount || parseFloat(amount) === 0) return;
  
    setIsApproving(true);
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ['function approve(address spender, uint256 amount) returns (bool)'],
      library.getSigner()
    );
  
    try {
      const requiredAmount = ethers.utils.parseUnits(amount, tokenDecimals);
      console.log('Approving amount:', ethers.utils.formatUnits(requiredAmount, tokenDecimals));
      const tx = await tokenContract.approve(spenderAddress, requiredAmount);
      await tx.wait();
      await checkAllowance();
      onApprovalSuccess();
    } catch (error) {
      console.error('Token approval failed:', error);
    } finally {
      setIsApproving(false);
    }
  };

  const needsApproval = useCallback(() => {
    if (isNativeCoin) return false;
    if (!amount || parseFloat(amount) === 0) return false;
    try {
      const requiredAmount = ethers.utils.parseUnits(amount, tokenDecimals);
      return currentAllowance.lt(requiredAmount);
    } catch (error) {
      console.error('Error in needsApproval calculation:', error);
      return true;
    }
  }, [isNativeCoin, amount, tokenDecimals, currentAllowance]);

  // 输出日志
  useEffect(() => {
    if (needsApproval()) {
      console.log('Needs approval:', true, 'Required:', amount, 'Current:', ethers.utils.formatUnits(currentAllowance, tokenDecimals));
    }
  }, [needsApproval, amount, currentAllowance, tokenDecimals]);

  if (isNativeCoin) {
    return renderButton(() => {}, true, '无需授权');
  }

  if (!isInputValid) {
    return renderButton(() => {}, true, '代币授权');
  }

  if (needsApproval()) {
    return renderButton(handleApprove, isApproving, isApproving ? '授权中...' : '代币授权');
  }

  return renderButton(() => {}, true, '已授权');
};

export default TokenApproval;