// getExplorerLink.ts

export function getExplorerLink(chainId: number, txHash: string): string {
    switch (chainId) {
      case 1: // Ethereum Mainnet
        return `https://etherscan.io/tx/${txHash}`;
      case 56: // Binance Smart Chain Mainnet
        return `https://bscscan.com/tx/${txHash}`;
      case 11155111: // Sepolia Testnet
        return `https://sepolia.etherscan.io/tx/${txHash}`;

      default:
        return `https://etherscan.io/tx/${txHash}`;
    }
  }