// ConfirmationModal.tsx
import React from 'react'
import styled from 'styled-components'
import { ButtonPrimary } from '../../components/Button'
import { 
  Percent, 
  Clock, 
  DollarSign, 
  TrendingUp, 
  TrendingDown,
  Zap
} from 'react-feather'

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  z-index: 50;
`

const Card = styled.div`
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
`

const CardContent = styled.div`
  padding: 1.5rem;
`

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const Title = styled.h2`
  font-size: 1.875rem;
  font-weight: 800;
  color: #1F2937;
  margin-bottom: 0.5rem;
`

const StatsContainer = styled.div`
  background: #F9FAFB;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 1.5rem;
`

const StatSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #E5E7EB;
  }
`

const SectionTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #4B5563;
  margin: 0 0 1rem 0;
`

const StatRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
`

const StatContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StatLabel = styled.span`
  font-size: 1rem;
  color: #4B5563;
`

const StatValue = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #2563EB;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`

const Button = styled(ButtonPrimary)`
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-weight: 500;
  text-align: center;
`

const CancelButton = styled(Button)`
  background: white;
  border: 1px solid #D1D5DB;
  color: #1F2937;

  &:hover {
    background: #F9FAFB;
  }
`


interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  slippageTolerance: number
  tradingDelay: number
  gasIncrease: number
  targetVolume: number
  buyPercentageRange: {
    min: number
    max: number
  }
  sellPercentageRange: {
    min: number
    max: number
  }
}

export function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  slippageTolerance,
  tradingDelay,
  gasIncrease,
  targetVolume,
  buyPercentageRange,
  sellPercentageRange
}: ConfirmationModalProps) {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <Card onClick={e => e.stopPropagation()}>
        <CardContent>
          <HeaderContainer>
            <Title>确认交易设置</Title>
          </HeaderContainer>

          <StatsContainer>
            <StatSection>
              <SectionTitle>基本设置</SectionTitle>
              <StatRow>
                <IconWrapper>
                  <Percent size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>滑点容差</StatLabel>
                  <StatValue>{slippageTolerance}%</StatValue>
                </StatContent>
              </StatRow>
              <StatRow>
                <IconWrapper>
                  <Clock size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>交易间隔</StatLabel>
                  <StatValue>{tradingDelay} 秒</StatValue>
                </StatContent>
              </StatRow>
              <StatRow>
                <IconWrapper>
                  <Zap size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>GAS 增幅</StatLabel>
                  <StatValue>{gasIncrease}%</StatValue>
                </StatContent>
              </StatRow>
            </StatSection>

            <StatSection>
              <SectionTitle>交易量与比例</SectionTitle>
              <StatRow>
                <IconWrapper>
                  <TrendingUp size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>买入比例范围</StatLabel>
                  <StatValue>{buyPercentageRange.min}% ~ {buyPercentageRange.max}%</StatValue>
                </StatContent>
              </StatRow>
              <StatRow>
                <IconWrapper>
                  <TrendingDown size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>卖出比例范围</StatLabel>
                  <StatValue>{sellPercentageRange.min}% ~ {sellPercentageRange.max}%</StatValue>
                </StatContent>
              </StatRow>
              <StatRow>
                <IconWrapper>
                  <DollarSign size={20} />
                </IconWrapper>
                <StatContent>
                  <StatLabel>需求目标交易量</StatLabel>
                  <StatValue>${targetVolume.toLocaleString()}</StatValue>
                </StatContent>
              </StatRow>
            </StatSection>
          </StatsContainer>

          <ButtonContainer>
            <Button onClick={onConfirm}>确认执行</Button>
            <CancelButton onClick={onClose}>取消</CancelButton>
          </ButtonContainer>
        </CardContent>
      </Card>
    </ModalOverlay>
  );
}