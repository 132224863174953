import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const InputLabel = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 0.5rem;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  font-size: 0.9rem;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.red1};
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const TableContainer = styled.div`
  margin-top: 1rem;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`;

const TableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationButton = styled.button`
  background-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.white};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EllipsisSpan = styled.span`
  margin: 0 0.25rem;
`;

interface Recipient {
  address: string;
  amount: string;
}

interface Props {
  recipients: Recipient[];
  setRecipients: (recipients: Recipient[]) => void;
  isPreviewMode?: boolean;
  setIsValidInput: (isValid: boolean) => void;
}

const MultiSenderAddressInput: React.FC<Props> = ({ 
  recipients, 
  setRecipients, 
  isPreviewMode = false,
  setIsValidInput
}) => {
  const [inputText, setInputText] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recipientsPerPage = 10;

  const validateAndSetRecipients = useMemo(() => (input: string) => {
    const lines = input.split('\n');
    const newRecipients: Recipient[] = [];
    const newErrors: string[] = [];
    const addresses = new Set();

    lines.forEach((line) => {
      if (line.trim() === '') return; // 跳过空行

      const [address, amount] = line.split(',');
      if (!address || !amount) {
        newErrors.push(`格式错误: ${line}`);
        return;
      }
      if (!ethers.utils.isAddress(address.trim())) {
        newErrors.push(`无效地址: ${address}`);
        return;
      }
      if (addresses.has(address.toLowerCase())) {
        newErrors.push(`重复地址: ${address}`);
        return;
      }
      if (isNaN(parseFloat(amount))) {
        newErrors.push(`无效金额: ${amount} (地址: ${address})`);
        return;
      }
      addresses.add(address.toLowerCase());
      newRecipients.push({ address: address.trim(), amount: amount.trim() });
    });

    setErrors(newErrors);
    setRecipients(newRecipients);
    setIsValidInput(newErrors.length === 0 && newRecipients.length > 0);
  }, [setRecipients, setIsValidInput]);

  useEffect(() => {
    if (!isPreviewMode) {
      validateAndSetRecipients(inputText);
    }
  }, [inputText, isPreviewMode, validateAndSetRecipients]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const currentRecipients = useMemo(() => {
    const indexOfLastRecipient = currentPage * recipientsPerPage;
    const indexOfFirstRecipient = indexOfLastRecipient - recipientsPerPage;
    return recipients.slice(indexOfFirstRecipient, indexOfLastRecipient);
  }, [recipients, currentPage]);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(recipients.length / recipientsPerPage);

  const paginationRange = useMemo(() => {
    const totalPageNumbers = 4;

    if (totalPageNumbers >= totalPages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let start = Math.max(1, Math.min(currentPage - 1, totalPages - 3));
    let end = Math.min(start + 3, totalPages);

    const result = [];

    if (start > 1) {
      result.push(1);
      if (start > 2) {
        result.push('...');
      }
    }

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        result.push('...');
      }
      result.push(totalPages);
    }

    return result;
  }, [currentPage, totalPages]);

  return (
    <>
      {!isPreviewMode && (
        <InputGroup>
          <InputLabel>接收地址和金额：</InputLabel>
          <StyledTextArea
            value={inputText}
            onChange={handleInputChange}
            placeholder="小写逗号区分地址与金额，例如：0x123456789078979789789,200"
          />
          {errors.map((error, index) => (
            <ErrorMessage key={index}>{error}</ErrorMessage>
          ))}
        </InputGroup>
      )}

      {isPreviewMode && recipients.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '70%' }} />
                <col style={{ width: '30%' }} />
              </colgroup>
              <thead>
                <tr>
                  <TableHeader>地址</TableHeader>
                  <TableHeader>数量</TableHeader>
                </tr>
              </thead>
              <tbody>
                {currentRecipients.map((recipient, index) => (
                  <tr key={index}>
                    <TableCell>{recipient.address}</TableCell>
                    <TableCell>{recipient.amount}</TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          <PaginationContainer>
            {paginationRange.map((pageNumber, index) => (
              <React.Fragment key={index}>
                {pageNumber === '...' ? (
                  <EllipsisSpan>...</EllipsisSpan>
                ) : (
                  <PaginationButton
                    onClick={() => typeof pageNumber === 'number' && paginate(pageNumber)}
                    disabled={currentPage === pageNumber}
                  >
                    {pageNumber}
                  </PaginationButton>
                )}
              </React.Fragment>
            ))}
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default MultiSenderAddressInput;