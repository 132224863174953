//bulk swap panel

import React, { useState, useEffect, useCallback, useMemo, useReducer, useRef } from 'react'
import styled from 'styled-components'
import { ButtonSecondary } from '../../components/Button'
import ImportWalletModal from './ImportWalletModal'
import CurrentTokenDisplay from './CurrentTokenDisplay'
import { useActiveWeb3React } from '../../hooks'
import { Field } from '../../state/swap/actions'
import { Currency, ETHER, Token } from 'swap-supproted-multichain-sdk'
import { ethers } from 'ethers'
import { getExplorerLink } from './getExplorerLink'
import { Wallet, WalletStatus } from './types'
import { Sliders, Shuffle } from 'react-bootstrap-icons'

const Switch = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 9999px;
  background-color: rgba(100, 116, 139, 0.377);
  transition: all .3s ease;

  &:checked {
    background-color: rgba(236, 72, 153, 1);
  }

  &::before {
    position: absolute;
    content: "";
    left: calc(1.5rem - 1.6rem);
    top: calc(1.5rem - 1.6rem);
    display: block;
    height: 1.6rem;
    width: 1.6rem;
    cursor: pointer;
    border: 1px solid rgba(100, 116, 139, 0.527);
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
    transition: all .3s ease;
  }

  &:hover::before {
    box-shadow: 0 0 0px 8px rgba(0, 0, 0, .15);
  }

  &:checked:hover::before {
    box-shadow: 0 0 0px 8px rgba(236, 72, 153, .15);
  }

  &:checked:before {
    transform: translateX(100%);
    border-color: rgba(236, 72, 153, 1);
  }
`

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px
`

const SettingsContainer = styled.div`
  background: ${({ theme }) => theme.bg2};
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
`

// SettingRow样式修改
const SettingRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
  }
`

// SettingLabel样式修改
const SettingLabel = styled.span`
  flex: 0 0 140px;
  color: ${({ theme }) => theme.text2};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }

  @media (max-width: 480px) {
    flex: none;
    margin-bottom: 0.25rem;
  }
`

// SliderContainer样式修改
const SliderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
  }
`

const Slider = styled.input<{ value: number; min: number; max: number }>`
  flex: 1;
  margin: 0 1rem;
  opacity: ${props => props.disabled ? 0.5 : 1};
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: ${({ value, min, max }) => `
    linear-gradient(to right, #D3D3D3, #FF69B4 ${((value - min) / (max - min)) * 100}%)
  `};
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid ${({ theme }) => theme.text3};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FF69B4;
    cursor: pointer;
  }
`

const ValueDisplay = styled.span`
  min-width: 60px; // Increased width for better alignment
  text-align: right;
  color: ${({ theme }) => theme.primary2};
  font-weight: 600;
`


const PanelContainer = styled.div`
  width: auto;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 0.5rem;
`

const TopActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
`

const WalletListFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.bg2};
  border-top: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.green1};
  font-size: 15px;
  width: 100%; // 确保footer占满整个宽度
`

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem; // 这会在两个span之间添加间隔
`

const TableCell = styled.td`
  padding: 0.5rem;
`

const TableContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`

const WalletListContainer = styled.div`
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
`

const WalletTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  font-weight: normal;
`

const StatusText = styled.span<{ status: WalletStatus }>`
  color: ${({ theme, status }) => 
    status === 'Approving' ? theme.primary3 :
    status === 'Swapping' ? theme.primary3 :
    status === 'Completed' ? theme.green1 :
    status === 'Failed' ? theme.red1 :
    status === 'InsufficientFee' ? theme.red1 :
    theme.text1
  };
`

const translateStatus = (status: WalletStatus): string => {
  switch (status) {
    case 'InsufficientFee':
      return '费用不足';
    case 'Pending':
      return '等待中';
    case 'Approving':
      return '授权中';
    case 'Swapping':
      return '交易中';
    case 'Completed':
      return '已完成';
    case 'Failed':
      return '失败';
      case 'NoBalance':
        return '没有余额';
    default:
      return status;
  }
};

interface BulkSwapPanelProps {
  inputCurrency: Currency | undefined
  outputCurrency: Currency | undefined
  independentField: Field
  onUserInput: (field: Field, typedValue: string) => void
  bulkSwapTotalBalance: string
  setBulkSwapTotalBalance: React.Dispatch<React.SetStateAction<string>>
  setSlippageTolerance: React.Dispatch<React.SetStateAction<number>>
  slippageTolerance : number
  transactions: { hash: string; address: string }[];
  setTransactions: React.Dispatch<React.SetStateAction<{ hash: string; address: string }[]>>;
  fetchBalances: () => Promise<void>;
  setSelectedWallets: React.Dispatch<React.SetStateAction<Wallet[]>>
  selectedWallets: Wallet[];
  currentGasPrice: string;
  gasIncrease: number;
  setGasIncrease: React.Dispatch<React.SetStateAction<number>>;
  handleGasIncreaseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type WalletAction = 
  | { type: 'IMPORT_WALLETS'; payload: Wallet[] }
  | { type: 'UPDATE_BALANCES'; payload: Partial<Wallet>[] }
  | { type: 'TOGGLE_SELECT_ALL'; payload: boolean }
  | { type: 'TOGGLE_WALLET_SELECTION'; payload: number }
  | { type: 'DELETE_SELECTED_WALLETS' }
  | { type: 'UPDATE_WALLET_STATUS'; payload: { address: string; status: WalletStatus; txHash?: string } }
  | { type: 'SYNC_WALLETS'; payload: Wallet[] }

function walletReducer(state: Wallet[], action: WalletAction): Wallet[] {
  switch (action.type) {
    case 'IMPORT_WALLETS':
      return [...state, ...action.payload]
    case 'UPDATE_BALANCES':
      return state.map(wallet => {
        const updatedWallet = action.payload.find(w => w.id === wallet.id)
        return updatedWallet ? { ...wallet, ...updatedWallet } : wallet
      })
    case 'TOGGLE_SELECT_ALL':
      return state.map(wallet => ({ ...wallet, selected: action.payload }))
    case 'TOGGLE_WALLET_SELECTION':
      return state.map(wallet => 
        wallet.id === action.payload ? { ...wallet, selected: !wallet.selected } : wallet
      )
    case 'DELETE_SELECTED_WALLETS':
      return state.filter(wallet => !wallet.selected)
    case 'UPDATE_WALLET_STATUS':
      return state.map(wallet => 
        wallet.address === action.payload.address 
          ? { ...wallet, status: action.payload.status, txHash: action.payload.txHash } 
          : wallet
      )
    case 'SYNC_WALLETS':
      return action.payload
    default:
      return state
  }
}

const BNB_CHAIN_ID = 56;

const isNativeCurrency = (currency: Currency | undefined, chainId: number | undefined): boolean => {
  if (!currency || chainId === undefined) return false;
  if (ETHER[chainId as keyof typeof ETHER] === currency) return true;
  if (currency.symbol === 'BNB' && chainId === BNB_CHAIN_ID) return true;
  return false;
}

const formatBalance = (balance: string, decimals: number) => {
  const minThreshold = 1 / Math.pow(10, decimals);
  const balanceNum = parseFloat(balance);
  
  if (balanceNum === 0) {
    return '0';
  } else if (balanceNum < minThreshold) {
    return `< ${minThreshold.toFixed(decimals)}`;
  } else if (balanceNum < 0.001) {
    return balanceNum.toExponential(2);
  } else {
    return balanceNum.toFixed(Math.min(4, decimals));
  }
}


export default function BulkSwapPanel({
  setSlippageTolerance,
  selectedWallets,
  slippageTolerance,
  setSelectedWallets,
  inputCurrency,
  outputCurrency,
  independentField,
  onUserInput,
  setBulkSwapTotalBalance,
  setTransactions,
  currentGasPrice,
  gasIncrease,
  setGasIncrease,
  handleGasIncreaseChange,
}: BulkSwapPanelProps) {
  const { library, chainId } = useActiveWeb3React()
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [managedWallets, dispatch] = useReducer(walletReducer, [])
  const [selectAll, setSelectAll] = useState(false)

  const balanceFetchingRef = useRef(false)
  const [isRandomized, setIsRandomized] = useState(false)
  const [globalSwapPercent, setGlobalSwapPercent] = useState(100)

  const inputTokenSymbol = inputCurrency?.symbol

  useEffect(() => {
    dispatch({ type: 'SYNC_WALLETS', payload: selectedWallets })
  }, [selectedWallets])

  const handleSwapPercentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newPercent = parseInt(e.target.value, 10)
    setGlobalSwapPercent(newPercent)
    if (!isRandomized) {
      setSelectedWallets(wallets => 
        wallets.map(wallet => ({
          ...wallet,
          swapPercent: newPercent
        }))
      )
    }
  }, [isRandomized, setSelectedWallets])

  //随机化
  const handleRandomizeToggle = useCallback(() => {
    setIsRandomized(prev => !prev)
    setSelectedWallets(wallets => 
      wallets.map(wallet => ({
        ...wallet,
        swapPercent: !isRandomized ? Math.floor(Math.random() * 90) + 1 : globalSwapPercent  //1/90%
      }))
    )
  }, [isRandomized, globalSwapPercent, setSelectedWallets])


  const fetchBalances = useCallback(async () => {
    if (!library || !inputCurrency || managedWallets.length === 0 || chainId === undefined || balanceFetchingRef.current) return;
  
    balanceFetchingRef.current = true;
    try {
      const provider = new ethers.providers.Web3Provider(library.provider);
      const batchSize = 10;
      const updatedWallets: Partial<Wallet>[] = [];
  
      for (let i = 0; i < managedWallets.length; i += batchSize) {
        const batch = managedWallets.slice(i, i + batchSize);
        const batchPromises = batch.map(async (wallet) => {
          let balance;
          if (isNativeCurrency(inputCurrency, chainId)) {
            balance = await provider.getBalance(wallet.address);
          } else if (inputCurrency instanceof Token) {
            const tokenContract = new ethers.Contract(
              inputCurrency.address,
              ['function balanceOf(address) view returns (uint256)'],
              provider
            );
            balance = await tokenContract.balanceOf(wallet.address);
          }
          
          if (balance) {
            const formattedBalance = ethers.utils.formatUnits(balance, inputCurrency.decimals);
            const displayBalance = formatBalance(formattedBalance, inputCurrency.decimals);
            updatedWallets.push({ id: wallet.id, balance: displayBalance });
          }
        });
  
        await Promise.all(batchPromises);
      }
  
      dispatch({ type: 'UPDATE_BALANCES', payload: updatedWallets });
    } catch (error) {
      console.error('Error fetching balances:', error);
    } finally {
      balanceFetchingRef.current = false;
    }
  }, [library, inputCurrency, managedWallets, chainId]);

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances, inputCurrency]); // 只在 inputCurrency 变化时刷新余额

  const handleImportWallets = useCallback((newWallets: { address: string; privateKey: string }[]) => {
    const existingAddresses = new Set(selectedWallets.map(w => w.address.toLowerCase()))
    const uniqueNewWallets: (Wallet & { swapPercent: number })[] = []
    const duplicateAddresses: string[] = []

    newWallets.forEach((wallet, index) => {
      const lowerCaseAddress = wallet.address.toLowerCase()
      if (!existingAddresses.has(lowerCaseAddress) && !uniqueNewWallets.some(w => w.address.toLowerCase() === lowerCaseAddress)) {
        uniqueNewWallets.push({
          ...wallet,
          id: Date.now() + index,
          status: 'Pending' as WalletStatus,
          selected: false,
          balance: '0',
          swapPercent: globalSwapPercent
        })
      } else {
        duplicateAddresses.push(wallet.address)
      }
    })

    if (uniqueNewWallets.length > 0) {
      dispatch({ type: 'IMPORT_WALLETS', payload: uniqueNewWallets })
      setSelectedWallets(prev => [...prev, ...uniqueNewWallets])
    }

    return { 
      importedWallets: uniqueNewWallets, 
      duplicateAddresses 
    }
  }, [selectedWallets, dispatch, setSelectedWallets, globalSwapPercent])

  const isWalletExisting = useCallback((address: string) => {
    return selectedWallets.some(wallet => wallet.address.toLowerCase() === address.toLowerCase())
  }, [selectedWallets])

  const toggleSelectAll = useCallback(() => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    dispatch({ type: 'TOGGLE_SELECT_ALL', payload: newSelectAll });
    setSelectedWallets(managedWallets.map(wallet => ({ ...wallet, selected: newSelectAll })));
  }, [selectAll, managedWallets, setSelectedWallets]);

  const toggleWalletSelection = useCallback((id: number) => {
    dispatch({ type: 'TOGGLE_WALLET_SELECTION', payload: id });
    setSelectedWallets(prevWallets => 
      prevWallets.map(wallet => 
        wallet.id === id ? { ...wallet, selected: !wallet.selected } : wallet
      )
    );
  }, [setSelectedWallets]);

  const deleteSelectedWallets = useCallback(() => {
    dispatch({ type: 'DELETE_SELECTED_WALLETS' })
    setSelectedWallets(prev => prev.filter(wallet => !wallet.selected))
    setSelectAll(false)
  }, [setSelectedWallets])

  const shortenAddress = useCallback((address: string) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`
  }, [])


  const totalSelectedBalance = useMemo(() => {
    const sum = managedWallets.filter(wallet => wallet.selected).reduce((sum, wallet) => {
      return sum + parseFloat(wallet.balance.replace(/[<>]/g, ''));
    }, 0);
    return formatBalance(sum.toString(), inputCurrency?.decimals || 18);
  }, [managedWallets, inputCurrency?.decimals]);

  const renderWalletRow = useCallback((wallet: Wallet & { swapPercent: number }) => {
    return (
      <tr key={wallet.id}>
        <TableCell>
          <input 
            type="checkbox" 
            checked={wallet.selected}
            onChange={() => toggleWalletSelection(wallet.id)}
          />
        </TableCell>
        <TableCell>{shortenAddress(wallet.address)}</TableCell>
        <TableCell>{wallet.balance}</TableCell>
        <TableCell>{wallet.swapPercent}%</TableCell>
        <TableCell>
          {wallet.txHash && chainId && (
            <a href={getExplorerLink(chainId, wallet.txHash)} target="_blank" rel="noopener noreferrer">
              {shortenAddress(wallet.txHash)}
            </a>
          )}
        </TableCell>
        <TableCell>
        <StatusText status={wallet.status}>{translateStatus(wallet.status)}</StatusText>
        </TableCell>
      </tr>
    )
  }, [chainId, toggleWalletSelection, shortenAddress])

// 修改状态定义为 number 类型
const [localSlippageTolerance, setLocalSlippageTolerance] = useState<number>(
  Number(slippageTolerance)  // 确保转换为数字类型
);

// 修改处理函数
const handleSlippageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  const value = parseFloat(e.target.value);
  if (!isNaN(value)) {
    setLocalSlippageTolerance(value);
    setSlippageTolerance(value);
  }
}, [setSlippageTolerance]);

  return (
    <PanelContainer>
      <TopActionContainer>
        <CurrentTokenDisplay />
        <ButtonSecondary onClick={() => setIsImportModalOpen(true)}>导入钱包</ButtonSecondary>
        <ButtonSecondary onClick={deleteSelectedWallets}>删除选中</ButtonSecondary>
      </TopActionContainer>
  
      <TableContainer>
        <WalletListContainer>
          <WalletTable>
            <TableHeader>
              <tr>
                <TableHeaderCell>
                  <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
                </TableHeaderCell>
                <TableHeaderCell>地址</TableHeaderCell>
                <TableHeaderCell>{inputTokenSymbol}</TableHeaderCell>
                <TableHeaderCell>比例%</TableHeaderCell>
                <TableHeaderCell>TX</TableHeaderCell>
                <TableHeaderCell>状态</TableHeaderCell>
              </tr>
            </TableHeader>
            <tbody>
              {managedWallets.map(renderWalletRow)}
            </tbody>
          </WalletTable>
        </WalletListContainer>
      </TableContainer>
  
      <WalletListFooter>
         {inputCurrency ? (
          <FooterContent>
           <span>钱包余额：{totalSelectedBalance} {inputTokenSymbol}</span>
          <span>选中钱包数量：{managedWallets.filter(wallet => wallet.selected).length}</span>
          </FooterContent>
        ) : (
          <span>未选择代币</span>
        )}
      </WalletListFooter>

      <SettingsContainer>
        <SettingRow>
          <SettingLabel>
            <Sliders size={20} /> 滑点设置 (%)
          </SettingLabel>
          <SliderContainer>
            <Slider
              type="range"
              min={0.1}
              max={49}
              step={0.1}
              value={localSlippageTolerance}
              onChange={handleSlippageChange}
            />
            <ValueDisplay>{(localSlippageTolerance).toFixed(1)}%</ValueDisplay>
          </SliderContainer>
        </SettingRow>
        <SettingRow>
          <SettingLabel>
            <Sliders size={20} /> 交易金额 (%)
          </SettingLabel>
          <SliderContainer>
            <Slider
              type="range"
              min={1}
              max={100}
              value={globalSwapPercent}
              onChange={handleSwapPercentChange}
              disabled={isRandomized}
            />
            <ValueDisplay>{isRandomized ? '随机' : `${globalSwapPercent}%`}</ValueDisplay>
          </SliderContainer>
        </SettingRow>
        <SettingRow>
          <SettingLabel>
            <Shuffle size={20} /> 随机金额 (%)
          </SettingLabel>
          <ToggleLabel>
            <Switch checked={isRandomized} onChange={handleRandomizeToggle} />
            <span style={{ marginLeft: '0.5rem' }}>{isRandomized ? '启用' : '关闭'}</span>
          </ToggleLabel>
        </SettingRow>
        <SettingRow>
          <SettingLabel>
            <Sliders size={20} /> GAS 增加 (%)
          </SettingLabel>
          <SliderContainer>
            <Slider
              type="range"
              min={0}
              max={100}
              value={gasIncrease}
              onChange={handleGasIncreaseChange}
            />
            <ValueDisplay>{gasIncrease}%</ValueDisplay>
          </SliderContainer>
        </SettingRow>
      </SettingsContainer>
      
      <ImportWalletModal
        isOpen={isImportModalOpen}
        onDismiss={() => setIsImportModalOpen(false)}
        onImport={handleImportWallets}
        isWalletExisting={isWalletExisting}
      />
    </PanelContainer>
  )
}