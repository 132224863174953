import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { ButtonPrimary } from '../Button'

const ModalContent = styled.div`
  padding: 20px;
  max-width: 600px;
  width: 90vw;
`

const ModalTitle = styled.h2`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.5rem;
  text-align: center;
`

const TermsText = styled.p`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 0.9rem;
  line-height: 1.5;
`

const SectionTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary1};
  font-size: 1.2rem;
`

const HighlightText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary1};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const AcceptButton = styled(ButtonPrimary)`
  width: 200px;
`

const TermsOfService: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    const checkTermsAccepted = () => {
      try {
        const hasAccepted = localStorage.getItem('termsAccepted') || sessionStorage.getItem('termsAccepted')
        if (hasAccepted === 'true') {
          setIsOpen(false)
        }
      } catch (error) {
        console.error('Error accessing storage:', error)
      }
    }

    checkTermsAccepted()
  }, [])

  const handleAccept = () => {
    try {
      localStorage.setItem('termsAccepted', 'true')
    } catch (error) {
      console.error('Error setting localStorage:', error)
      try {
        sessionStorage.setItem('termsAccepted', 'true')
      } catch (sessionError) {
        console.error('Error setting sessionStorage:', sessionError)
      }
    }
    setIsOpen(false)
  }

  return (
    <Modal isOpen={isOpen} onDismiss={() => {}} minHeight={false} maxHeight={90}>
      <ModalContent>
        <ModalTitle>服务条款</ModalTitle>
        <TermsText>
          请在使用本工具之前仔细阅读以下条款。使用本工具即表示您已理解并同意以下内容：
        </TermsText>
        <SectionTitle>本地运行声明</SectionTitle>
        <TermsText>
          <HighlightText>本工具为纯本地运行软件，</HighlightText>采用本地内存池方式储存您的数据，任何操作在刷新页面或关闭浏览器后都会丢失，绝无任何上传行为，用户的数据不会被上传到任何外部服务器或网络。
        </TermsText>
        <SectionTitle>使用限制</SectionTitle>
        <TermsText>
          <HighlightText>本工具仅用于最终的资金归集操作。</HighlightText>导入至本工具的钱包在操作完成后不可再次重复使用，我们建议在完成操作后妥善管理或废弃相关钱包。
        </TermsText>
        <SectionTitle>责任声明</SectionTitle>
        <TermsText>
          本工具仅提供技术支持，对因使用工具而造成的任何资金损失、被盗等情况概不负责。使用本工具即表示您已接受并同意以上条款。
        </TermsText>
        <ButtonContainer>
          <AcceptButton onClick={handleAccept}>我已阅读并同意</AcceptButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}

export default TermsOfService