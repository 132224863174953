// index.tsx

import React, { useCallback, useContext, useState, useMemo, SetStateAction, useEffect} from 'react'
import { ArrowDown } from 'react-feather'
import { ThemeContext } from 'styled-components'
import { ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/BulkCurrencyInputPanel'
import { AutoRow } from '../../components/Row'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/BulkSwap/styleds'
//import SwapHeader from '../../components/BulkSwap/SwapHeader'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useSwapActionHandlers, useSwapState } from '../../state/swap/hooks'
import BulkSwapPanel from './BulkSwapPanel'
import BulkSwapExecutor from './BulkSwapExecutor'
import styled from 'styled-components'
import { Currency, Token } from 'swap-supproted-multichain-sdk'
import { ethers } from 'ethers'
import { getTokenPrice } from './tokenPriceQuery'
import SwapTitle from './SwapTitle'
import {  WalletStatus, WalletPair } from './types'


const PageWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0.5rem;

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const SwapAndBulkWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const SwapWrapper = styled.div`
  flex: 0 0 580px;
  padding-right: 1rem;

  @media (max-width: 480px) {
    flex: 1;
    padding-right: 0;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.bg3};
  margin: 0 1rem;

  @media (max-width: 480px) {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
  }
`;

const BulkSwapWrapper = styled.div`
  flex: 1;
  min-width: 480px;
  max-width: 680px;

  @media (max-width: 480px) {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  }
`;

const InfoContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.bg2};
  padding: 1rem;
  margin: 15px 0 1rem 0;
`

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.text2};
`

const InfoValue = styled.span`
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
`

const Divider2 = styled.div`
  height: 1px;
  background-color: ${props => props.theme.bg3};
  margin: 10px 0;
`

const InfoText = styled.div`
  white-space: pre-wrap;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.green1};
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 0.5rem;
`

export default function Swap() {
  const theme = useContext(ThemeContext)
  const toggleWalletModal = useWalletModalToggle()
  const { account, library, chainId  } = useActiveWeb3React()

  const { independentField, typedValue, [Field.INPUT]: { currencyId: inputCurrencyId }, [Field.OUTPUT]: { currencyId: outputCurrencyId } } = useSwapState()
  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const [bulkSwapTotalBalance, setBulkSwapTotalBalance] = useState('0')
  const [walletPairs, setWalletPairs] = useState<WalletPair[]>([])
  const [transactions, setTransactions] = useState<{ hash: string; address: string }[]>([]);
  const [tradingDelay, setTradingDelay] = useState<number>(5) 
  const [slippageTolerance, setSlippageTolerance] = useState(15)
  const [targetVolume, setTargetVolume] = useState(10000)
  const [totalVolume, setTotalVolume] = useState<number>(0);

  const handleTypeInput = useCallback((value: string) => {
    const cleanValue = value.replace(/\.0+$/, '')
    onUserInput(Field.INPUT, cleanValue)
  }, [onUserInput])

  const handleTypeOutput = useCallback((value: string) => {
    const cleanValue = value.replace(/\.0+$/, '')
    onUserInput(Field.OUTPUT, cleanValue)
  }, [onUserInput])

  const isExactIn = independentField === Field.INPUT

  const updateWalletStatus = useCallback((address: string, status: WalletStatus, txHash?: string) => {
    setWalletPairs(prevPairs => 
      prevPairs.map(pair => ({
        ...pair,
        walletA: pair.walletA.address === address 
          ? { ...pair.walletA, status, txHash: txHash || pair.walletA.txHash }
          : pair.walletA,
        walletB: pair.walletB && pair.walletB.address === address
          ? { ...pair.walletB, status, txHash: txHash || pair.walletB.txHash }
          : pair.walletB
      }))
    )
  }, [])

  const fetchBalances = useCallback(async () => {
  }, [])

  const formattedAmounts = useMemo(() => ({
    [Field.INPUT]: independentField === Field.INPUT ? typedValue : '',
    [Field.OUTPUT]: independentField === Field.OUTPUT ? typedValue : ''
  }), [independentField, typedValue])

  const handleInputSelect = useCallback((inputCurrency: Currency) => {
    onCurrencySelection(Field.INPUT, inputCurrency)
  }, [onCurrencySelection])
  
  const handleOutputSelect = useCallback((outputCurrency: Currency) => {
    onCurrencySelection(Field.OUTPUT, outputCurrency)
  }, [onCurrencySelection])
  
  const handleSwitchTokens = useCallback(() => {
    onSwitchTokens()
  }, [onSwitchTokens])

  const handleMaxInput = useCallback(() => {
    if (bulkSwapTotalBalance) {
      onUserInput(Field.INPUT, bulkSwapTotalBalance)
    }
  }, [bulkSwapTotalBalance, onUserInput])

  const [currentGasPrice, setCurrentGasPrice] = useState<string>('0');
  const [gasIncrease, setGasIncrease] = useState<number>(10);

  const fetchCurrentGasPrice = useCallback(async () => {
    if (library) {
      const gasPrice = await library.getGasPrice();
      setCurrentGasPrice(ethers.utils.formatUnits(gasPrice, 'gwei'));
    }
  }, [library]);

  useEffect(() => {
    fetchCurrentGasPrice();
    const interval = setInterval(fetchCurrentGasPrice, 20000); // 20秒更新一次
    return () => clearInterval(interval);
  }, [fetchCurrentGasPrice]);

  const handleGasIncreaseChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setGasIncrease(value);
  }, []);

  const [inputTokenPrice, setInputTokenPrice] = useState<number | null>(null);
  const [outputTokenPrice, setOutputTokenPrice] = useState<number | null>(null);

  const formatPriceByTokenDecimals = (price: number | null, currency: Currency | null | undefined) => {
    if (price === null || !currency) return '未选择';
    
    const EXTRA_DIGITS = 3; 
  
    // 将价格转换为字符串
    let priceString = price.toFixed(18);
  
    // 找到小数点的位置
    const decimalIndex = priceString.indexOf('.');
  
    if (decimalIndex === -1) {
      // 如果没有小数点，直接返回整数部分
      return `$${priceString}`;
    }
  
    // 找到第一个非零数字的位置
    const firstNonZeroIndex = priceString.slice(decimalIndex).search(/[1-9]/);
  
    if (firstNonZeroIndex === -1) {
      // 如果小数部分没有非零数字，返回0
      return '$0';
    }
  
    // 计算需要保留的小数位数
    const significantDigits = firstNonZeroIndex + EXTRA_DIGITS;
  
    // 截取需要的部分
    let formattedPrice = priceString.slice(0, decimalIndex + significantDigits + 1);
  
    // 移除尾随的零和可能的小数点
    formattedPrice = formattedPrice.replace(/\.?0+$/, '');
  
    return `$${formattedPrice}`;
  };

  const getTokenAddress = useCallback((currency: Currency | null | undefined): string | null => {
    if (!currency) return null;
    if (currency instanceof Token) return currency.address;
    // 原生代币
    if (chainId === 1) return '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
    if (chainId === 56) return '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    if (chainId === 11155111) return '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14';
    return null;
  }, [chainId]);

  useEffect(() => {
    async function fetchTokenPrices() {
      if (chainId) {
        const inputAddress = getTokenAddress(inputCurrency);
        const outputAddress = getTokenAddress(outputCurrency);
  
        if (inputAddress) {
          const price = await getTokenPrice(inputAddress, chainId.toString());
          setInputTokenPrice(price);
        } else {
          setInputTokenPrice(null);
        }
        
        if (outputAddress) {
          const price = await getTokenPrice(outputAddress, chainId.toString());
          setOutputTokenPrice(price);
        } else {
          setOutputTokenPrice(null);
        }
      }
    }
    fetchTokenPrices();
  }, [inputCurrency, outputCurrency, chainId, getTokenAddress]);

  const renderSwapInfo = useMemo(() => (
    <InfoContainer>
      <InfoItem>
        <InfoLabel>交易方向</InfoLabel>
        <InfoValue>市值管理</InfoValue>
      </InfoItem>
      <Divider2 />
      <InfoItem>
        <InfoLabel>{inputCurrency?.symbol || '输入'} 价格</InfoLabel>
        <InfoValue>{formatPriceByTokenDecimals(inputTokenPrice, inputCurrency)}</InfoValue>
      </InfoItem>
      <InfoItem>
        <InfoLabel>{outputCurrency?.symbol || '输出'} 价格</InfoLabel>
        <InfoValue>{formatPriceByTokenDecimals(outputTokenPrice, outputCurrency)}</InfoValue>
      </InfoItem>
      <Divider2 />
      <InfoItem>
        <InfoLabel>GAS费率</InfoLabel>
        <InfoValue>{currentGasPrice} Gwei</InfoValue>
      </InfoItem>
      <Divider2 />
      <InfoItem>
        <InfoLabel>增加GAS</InfoLabel>
        <InfoValue>{gasIncrease}%</InfoValue>
      </InfoItem>
    </InfoContainer>
  ), [inputCurrency, outputCurrency, inputTokenPrice, outputTokenPrice, currentGasPrice, gasIncrease]);

  const memoizedOnUserInput = useCallback((field: Field, value: string) => {
    onUserInput(field, value)
  }, [onUserInput])
  
  const memoizedSetBulkSwapTotalBalance = useCallback((value: SetStateAction<string>) => {
    setBulkSwapTotalBalance(value)
  }, [])
  
  const memoizedSetGasIncrease = useCallback((value: SetStateAction<number>) => {
    setGasIncrease(value)
  }, [])

  
  const bulkSwapPanelProps = useMemo(() => ({
    inputCurrency: inputCurrency ?? undefined,
    outputCurrency: outputCurrency ?? undefined,
    independentField,
    onUserInput: memoizedOnUserInput,
    bulkSwapTotalBalance,
    setBulkSwapTotalBalance: memoizedSetBulkSwapTotalBalance,
    setTransactions,
    fetchBalances,
    setSlippageTolerance,
    currentGasPrice,
    gasIncrease,
    setGasIncrease: memoizedSetGasIncrease,
    handleGasIncreaseChange,
    tradingDelay,
    slippageTolerance,
    targetVolume,
    setTargetVolume,
    setTradingDelay,
    walletPairs,
    setWalletPairs,
  }), [
    inputCurrency, 
    outputCurrency, 
    independentField, 
    memoizedOnUserInput, 
    bulkSwapTotalBalance, 
    memoizedSetBulkSwapTotalBalance, 
    setSlippageTolerance, 
    transactions, 
    setTransactions, 
    currentGasPrice, 
    gasIncrease, 
    memoizedSetGasIncrease, 
    fetchBalances, 
    slippageTolerance,
    targetVolume,
    handleGasIncreaseChange,
    walletPairs,
    setWalletPairs,
    tradingDelay,
    setTradingDelay,
  ])

  const [buyPercentageRange, setBuyPercentageRange] = useState({ min: 10, max: 95 });
  const [sellPercentageRange, setSellPercentageRange] = useState({ min: 10, max: 100 });

  //确保所有设置都是最新的
  const tradeSettings = useMemo(() => ({
    slippageTolerance,
    targetVolume,
    buyPercentageRange,
    sellPercentageRange,
    tradingDelay,
    gasIncrease
  }), [
    slippageTolerance,
    targetVolume,
    buyPercentageRange,
    sellPercentageRange,
    tradingDelay,
    gasIncrease
  ]);

// syncSettings 为异步函数
const syncSettings = useCallback(async () => {
  return new Promise<void>((resolve) => {
    // 使用 tradeSettings 中的最新值
    setSlippageTolerance(tradeSettings.slippageTolerance);
    setTargetVolume(tradeSettings.targetVolume);
    setBuyPercentageRange(tradeSettings.buyPercentageRange);
    setSellPercentageRange(tradeSettings.sellPercentageRange);
    setTradingDelay(tradeSettings.tradingDelay);
    
    // 使用 requestAnimationFrame 确保状态更新
    requestAnimationFrame(() => {
      resolve();
    });
  });
}, [tradeSettings]);

  const renderSwapButton = useMemo(() => (
    <BottomGrouping>
      {!account ? (
        <ButtonLight onClick={toggleWalletModal}>连接钱包</ButtonLight>
      ) : (
        <BulkSwapExecutor
          walletPairs={walletPairs}
          setWalletPairs={setWalletPairs}
          inputCurrency={inputCurrency ?? undefined}
          outputCurrency={outputCurrency ?? undefined}
          independentField={independentField}
          slippageTolerance={slippageTolerance}
          fetchBalances={fetchBalances}
          setTransactions={setTransactions}
          updateWalletStatus={updateWalletStatus}
          gasPrice={currentGasPrice}
          gasIncrease={gasIncrease}
          library={library}
          tradingDelay={tradingDelay}
          buyPercentageRange={buyPercentageRange}
          sellPercentageRange={sellPercentageRange}
          totalTradingVolume={parseFloat(bulkSwapTotalBalance)}
          targetVolume={targetVolume}
          currentTradingVolume={totalVolume}
          onBeforeExecute={syncSettings}
        />
      )}
    </BottomGrouping>
  ), [account, walletPairs, inputCurrency, outputCurrency, independentField, slippageTolerance, toggleWalletModal, setTransactions, updateWalletStatus, currentGasPrice, gasIncrease, library, fetchBalances,tradeSettings,syncSettings, tradingDelay])
  

  return (
    <PageWrapper>
      <SwapAndBulkWrapper>
        <SwapWrapper>
        <SwapTitle />
          <Wrapper id="swap-page">
            <AutoColumn gap={'md'}>
              <CurrencyInputPanel
                label={isExactIn ? '从' : '从'}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={false}
                currency={inputCurrency ?? undefined}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                otherCurrency={outputCurrency ?? undefined}
                id="swap-currency-input"
                key={`input-${inputCurrencyId}`}
                hideBalance={true}
                disableInput={true}
              />
              <AutoColumn justify="space-between">
                <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable>
                    <ArrowDown
                      size="16"
                      onClick={handleSwitchTokens}
                      color={inputCurrency && outputCurrency ? theme.primary1 : theme.text2}
                    />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>
              <CurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                label={isExactIn ? '到' : '到'}
                showMaxButton={false}
                currency={outputCurrency ?? undefined}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={inputCurrency ?? undefined}
                id="swap-currency-output"
                key={`output-${outputCurrencyId}`}
                hideBalance={true}
                disableInput={true}
              />
            </AutoColumn>
            <InfoText>{`BOT市值逻辑：
钱包A买入代币 → 钱包B接收代币 → 钱包B卖出代币 → 资金回到钱包A
每一组钱包搭配跟您导入时的循序相同，交易时请勿刷新网页，需挂机执行。`}</InfoText>
            {renderSwapInfo}
            {renderSwapButton}            
          </Wrapper>
        </SwapWrapper>
        <Divider />
        
        <BulkSwapWrapper>
        <BulkSwapPanel 
            {...bulkSwapPanelProps}
            walletPairs={walletPairs}
            setWalletPairs={setWalletPairs}
            buyPercentageRange={buyPercentageRange}
            setBuyPercentageRange={setBuyPercentageRange}
            sellPercentageRange={sellPercentageRange}
            setSellPercentageRange={setSellPercentageRange}
            targetVolume={targetVolume}
            setTargetVolume={setTargetVolume}
            setSlippageTolerance={setSlippageTolerance}
            onTradingVolumeChange={(volume: string) => {
              setTotalVolume(parseFloat(volume));
            }}
          />
        </BulkSwapWrapper>
      </SwapAndBulkWrapper>
    </PageWrapper>
  )
}