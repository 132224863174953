import React, { useState, useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import styled from 'styled-components';
import axios from 'axios';
import { ChevronDown } from 'react-bootstrap-icons';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const InputContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 2.5rem;
  padding: 0 2.5rem 0 0.75rem;
  border-radius: 8px 0 0 0 ;
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-right: none;

  &:focus {
    border-color: ${({ theme }) => theme.bg2};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }
`;

const DropdownButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2.5rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary1};
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-top: none;
  border-radius: 0 0 8px 8px;
  z-index: 10;
  margin-top: -1px;
`;

const DropdownItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary4};
  }
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 0 1rem;
  height: 2.5rem;
  border: 1px solid ${({ theme, active }) => (active ? theme.primary1 : theme.bg3)};
  border-left: none;
  border-radius: 0 8px 8px 0;
  background-color: ${({ theme, active }) => (active ? theme.primary1 : theme.primary3)};
  color: ${({ theme, active }) => (active ? theme.white : theme.text1)};
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.white};
  }
`;

interface Token {
  token_address: string;
  symbol: string;
  balance: string;
  decimals: number;
}

interface TokenSelectInputProps {
  onSelect: (address: string) => void;
  placeholder: string;
  isNativeCoin: boolean;
  onToggleNativeCoin: () => void;
  nativeCoinSymbol: string;
}

const TokenSelectInput: React.FC<TokenSelectInputProps> = ({ 
  onSelect, 
  placeholder, 
  isNativeCoin, 
  onToggleNativeCoin, 
  nativeCoinSymbol 
}) => {
  const [input, setInput] = useState('');
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { account, chainId, } = useWeb3React();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (account && chainId) {
      fetchUserTokens();
    }
  }, [account, chainId]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const fetchUserTokens = async () => {
    const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjRkYmRkZmE5LTYxZDUtNDg1My1hYzRhLTY5NTRiYTBhODFjZiIsIm9yZ0lkIjoiNDEwOTQ0IiwidXNlcklkIjoiNDIyMzAyIiwidHlwZUlkIjoiNWUxZDRiN2YtZTc3NS00ZDQ0LTk4MTAtYjNjNzg2Yjk5MWM4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjgzODYzNTAsImV4cCI6NDg4NDE0NjM1MH0.BaYrSrphPqhXpDr813aVJiu6j0hQ7CZmghJ6yqnhOmg';
    let chain = '';
    switch (chainId) {
      case 1:
        chain = 'eth';
        break;
      case 56:
        chain = 'bsc';
        break;
      case 11155111:
        chain = 'sepolia';
        break;
      default:
        console.error('Unsupported chain');
        return;
    }

    try {
      const response = await axios.get(`https://deep-index.moralis.io/api/v2/${account}/erc20`, {
        params: { chain },
        headers: { 'X-API-Key': MORALIS_API_KEY }
      });

      const userTokens: Token[] = response.data.map((token: any) => ({
        token_address: token.token_address,
        symbol: token.symbol,
        balance: ethers.utils.formatUnits(token.balance, token.decimals),
        decimals: token.decimals
      }));

      setTokens(userTokens.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance)));
    } catch (error) {
      console.error('Error fetching user tokens:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    onSelect(e.target.value);
  };

  const handleSelect = (token: Token) => {
    setInput(token.token_address);
    onSelect(token.token_address);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <InputWrapper ref={wrapperRef}>
      <InputContainer>
        <StyledInput
          value={isNativeCoin ? nativeCoinSymbol : input}
          onChange={handleInputChange}
          placeholder={placeholder}
          disabled={isNativeCoin}
        />
        <DropdownButton onClick={toggleDropdown}>
          <ChevronDown size={18} />
        </DropdownButton>
        {showDropdown && (
          <DropdownList>
            {tokens.map((token, index) => (
              <DropdownItem key={index} onClick={() => handleSelect(token)}>
                {token.symbol} - {parseFloat(token.balance).toFixed(4)}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </InputContainer>
      <ToggleButton
        active={isNativeCoin}
        onClick={onToggleNativeCoin}
      >
        {nativeCoinSymbol}
      </ToggleButton>
    </InputWrapper>
  );
};

export default TokenSelectInput;