//bulk swap executor

import React, { useCallback, useState } from 'react'
import { ButtonPrimary } from '../../components/Button'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { getRouterAddress } from '../../constants'
import { Currency, TokenAmount, JSBI, Token, WETH, Percent } from 'swap-supproted-multichain-sdk'
import { ethers } from 'ethers'
import { Field } from '../../state/swap/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { MaxUint256 } from '@ethersproject/constants'
import { Wallet, WalletStatus } from './types'
import { TransactionResponse } from '@ethersproject/providers'
import { Web3Provider } from '@ethersproject/providers'


const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

const Loader = styled.div`
  --col1: rgba(228, 19, 141, 0.925);
  --col2: rgb(255, 179, 80);
  font-size: 2em;
  font-weight: 600;
  perspective: 800px;
  position: relative;

  &::after,
  &::before,
  .loader-text::after,
  .loader-text::before {
    perspective: 800px;
    animation: anim 2s ease-in-out infinite, dotMove 10s ease-out alternate infinite, move 10s linear infinite 1s;
    content: '●';
    color: var(--col1);
    position: absolute;
    translate: -60px 0px; 
    width: 5px;
    height: 5px;
  }

  &::before {
    animation-delay: 3s;
    color: var(--col1);
  }

  .loader-text::before {
    color: var(--col2);
    animation-delay: 2s;
  }

  .loader-text::after {
    color: var(--col2);
  }

  .loader-text {
    animation: anim 20s linear infinite, move 10s linear infinite 1s;
    color: transparent;
    background-image: linear-gradient(90deg, 
    var(--col1) 0%,
    var(--col2) 100%);
    background-clip: text;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: skew(5deg, -5deg);
    -webkit-background-clip: text;
    position: relative;
  }

  @keyframes anim {
    0%, 100% {
      text-shadow: 2px 0px 2px rgba(179, 158, 158, .5);
    }

    50% {
      background-size: 0%;
      background-position-x: left;
      text-shadow: 2px 10px 6px rgba(179, 158, 158, 1);
    }
  }

  @keyframes move {
    50% {
      translate: 0px -30px;
      rotate: x 60deg;
      transform: skew(-5deg, 5deg);
    }
  }

  @keyframes dotMove {
    0%, 100% {
      translate: -60px 0px;
    }

    50% {
      translate: 160px -250px;
      scale: .5;
      opacity: .85;
    }
  }
`

const ExecutorContainer = styled.div`
  margin-top: 1rem;
  width: 70%;
  margin: 0 auto;
`

interface BulkSwapExecutorProps {
  inputCurrency: Currency | undefined
  outputCurrency: Currency | undefined
  independentField: Field
  slippageTolerance: number
  setTransactions: React.Dispatch<React.SetStateAction<{ hash: string; address: string }[]>>
  updateWalletStatus: (address: string, status: WalletStatus, txHash?: string) => void
  fetchBalances: () => Promise<void>
  selectedWallets: (Wallet & { swapPercent: number })[]
  gasPrice: string
  gasIncrease: number
  library: Web3Provider | undefined
}

const WBNB = new Token(
  56,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Wrapped BNB'
)

const WBNB_TESTNET = new Token(
  97,
  '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  18,
  'WBNB',
  'Wrapped BNB'
)

function getWrappedNative(chainId: number): Token {
  switch (chainId) {
    case 1: // Ethereum Mainnet
    case 11155111: // Sepolia testnet
      return WETH[chainId as keyof typeof WETH];
    case 56: // BSC Mainnet
      return WBNB;
    case 97: // BSC Testnet
      return WBNB_TESTNET;
    default:
      throw new Error(`Unsupported chain ID: ${chainId}`);
  }
}

function hasIsNative(currency: any): currency is { isNative: boolean } {
  return 'isNative' in currency;
}

function hasIsEther(currency: any): currency is { isEther: boolean } {
  return 'isEther' in currency;
}

function isNativeCurrency(currency: Currency): boolean {
  // 检查原生币
  if (hasIsNative(currency) && currency.isNative) return true;
  if (hasIsEther(currency) && currency.isEther) return true;
  
  // 检查符号
  const nativeSymbols = ['ETH', 'BNB', 'TBNB', 'SepETH'];
  return nativeSymbols.includes(currency.symbol || '');
}

function ensureToken(currency: Currency | Token | undefined, chainId: number): Token | undefined {
  if (!currency) return undefined;
  if ('address' in currency) return currency as Token;
  if (isNativeCurrency(currency)) {
    return getWrappedNative(chainId);
  }
  return undefined;
}

export default function BulkSwapExecutor({
  selectedWallets,
  inputCurrency,
  outputCurrency,
  independentField,
  fetchBalances,
  updateWalletStatus,
  slippageTolerance,
  setTransactions,
  gasPrice,
  library,
  gasIncrease
}: BulkSwapExecutorProps) {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [isExecuting, setIsExecuting] = useState(false)


  //重置状态函数
  const resetWalletStatuses = useCallback(() => {
    selectedWallets.forEach(wallet => {
      updateWalletStatus(wallet.address, 'Pending');
    });
  }, [selectedWallets, updateWalletStatus]);

  const executeBulkSwap = useCallback(async () => {

    console.log('========== 交易设置 ==========');
    console.log(`滑点设置: ${slippageTolerance}%`);
    console.log('============================');

    resetWalletStatuses(); // 重置所有钱包状态
    setTransactions([])
    setIsExecuting(true); // 在函数开始时设置为 true
    try {
      if (!chainId || !library || !inputCurrency || !outputCurrency) return;
  
    const routerAddress = getRouterAddress(chainId);
    const inputToken = ensureToken(inputCurrency, chainId);
    const outputToken = ensureToken(outputCurrency, chainId);

    const isNativeInput = isNativeCurrency(inputCurrency);
    const isNativeOutput = isNativeCurrency(outputCurrency);

  
    if (!inputToken || !outputToken) return;
  
    const router = new ethers.Contract(
      routerAddress,
      [
        'function swapExactTokensForTokensSupportingFeeOnTransferTokens(uint256 amountIn, uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external',
        'function swapExactETHForTokensSupportingFeeOnTransferTokens(uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external payable',
        'function swapExactTokensForETHSupportingFeeOnTransferTokens(uint256 amountIn, uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external'
      ],
      library.getSigner()
    )
  
    const deadline = Math.floor(Date.now() / 1000) + 1200 // 20分钟后
  
    const baseGasPrice = ethers.utils.parseUnits(gasPrice, 'gwei');
    const adjustedGasPrice = baseGasPrice.mul(100 + gasIncrease).div(100);
  
    const selectedWalletsForSwap = selectedWallets.filter(w => w.selected);
    const batchSize = 10; // 每批处理的钱包数量
  
    for (let i = 0; i < selectedWalletsForSwap.length; i += batchSize) {
      const batch = selectedWalletsForSwap.slice(i, i + batchSize);
      
      const swapPromises = batch.map(async (wallet) => {
        try {
          const walletSigner = new ethers.Wallet(wallet.privateKey, library)
          
          let currentBalance;
          if (isNativeInput) {
            currentBalance = await library.getBalance(wallet.address);
          } else {
            const tokenContract = new ethers.Contract(
              inputToken.address,
              ['function balanceOf(address) view returns (uint256)'],
              library
            );
            currentBalance = await tokenContract.balanceOf(wallet.address);
          }
  
          let swapAmountRaw = JSBI.divide(
            JSBI.multiply(
              JSBI.BigInt(currentBalance.toString()),
              JSBI.BigInt(wallet.swapPercent)
            ),
            JSBI.BigInt(100)
          )
  
          if (wallet.swapPercent === 100 && isNativeInput) {
            const estimatedGasCost = adjustedGasPrice.mul(300000)
            const reserveForGas = JSBI.multiply(JSBI.BigInt(estimatedGasCost.toString()), JSBI.BigInt(2))
            swapAmountRaw = JSBI.subtract(swapAmountRaw, reserveForGas)
          }
  
          const swapAmount = new TokenAmount(inputToken, swapAmountRaw)
  
          if (JSBI.equal(swapAmount.raw, JSBI.BigInt(0))) {
            console.log(`钱包 ${wallet.address} 的交易金额为0，跳过`)
            updateWalletStatus(wallet.address, 'NoBalance');
            return;
          }

          updateWalletStatus(wallet.address, 'Approving')
  
          if (!isNativeInput) {
            const tokenContract = new ethers.Contract(
              inputToken.address,
              ['function allowance(address owner, address spender) view returns (uint256)', 'function approve(address spender, uint256 amount) returns (bool)'],
              walletSigner
            )
          
            const currentAllowance = await tokenContract.allowance(wallet.address, routerAddress)
            if (currentAllowance.lt(swapAmount.raw.toString())) {
              console.log(`为钱包 ${wallet.address} 授权`)
              
              // 估算授权交易的 gas limit
              const estimatedApproveGasLimit = await tokenContract.estimateGas.approve(routerAddress, MaxUint256)
              
              // 增加 100,000 单位的 gas
              const approveGasLimit = estimatedApproveGasLimit.add(100000)
              
              const approveTx = await tokenContract.approve(routerAddress, MaxUint256, {
                gasPrice: adjustedGasPrice,
                gasLimit: approveGasLimit
              })
              await approveTx.wait()
            }
          }
  
          const path = [inputToken.address, outputToken.address]
          const slippagePercent = new Percent(JSBI.BigInt(Math.floor(slippageTolerance * 100)), JSBI.BigInt(10000))
          let method, params
  
          const minOut = swapAmount.multiply(JSBI.subtract(JSBI.BigInt(10000), slippagePercent.numerator)).divide(JSBI.BigInt(10000))
          
          if (isNativeInput) {
            method = 'swapExactETHForTokensSupportingFeeOnTransferTokens'
            params = [minOut.quotient.toString(), path, wallet.address, deadline]
          } else if (isNativeOutput) {
            method = 'swapExactTokensForETHSupportingFeeOnTransferTokens'
            params = [swapAmount.raw.toString(), minOut.quotient.toString(), path, wallet.address, deadline]
          } else {
            method = 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
            params = [swapAmount.raw.toString(), minOut.quotient.toString(), path, wallet.address, deadline]
          }
      
          const routerWithSigner = router.connect(walletSigner)
  
          // 预估 gas limit
          const estimatedGasLimit = await routerWithSigner.estimateGas[method](...params, {
            value: isNativeInput ? swapAmount.raw.toString() : '0',
            gasPrice: adjustedGasPrice
          })
  
          // 增加 100,000 单位的 gas
          const gasLimit = estimatedGasLimit.add(100000)
  
          updateWalletStatus(wallet.address, 'Swapping')
          
          const tx: TransactionResponse = await routerWithSigner[method](...params, {
            value: isNativeInput ? swapAmount.raw.toString() : '0',
            gasPrice: adjustedGasPrice,
            gasLimit: gasLimit
          })
          
          updateWalletStatus(wallet.address, 'Swapping', tx.hash)
          setTransactions(prev => [...prev, { hash: tx.hash, address: wallet.address }])
          
          addTransaction(tx, {
            summary: `批量兑换 ${wallet.swapPercent}% 的 ${inputCurrency.symbol} 换成 ${outputCurrency.symbol}，从钱包 ${wallet.address}`
          })
  
          const receipt = await tx.wait()
          console.log(`钱包 ${wallet.address} 的交易已确认`)
          updateWalletStatus(wallet.address, 'Completed', receipt.transactionHash)
        } catch (error) {
          console.error(`钱包 ${wallet.address} 的交易失败:`, error)
          
          let errorStatus: WalletStatus = 'Failed'
          
          if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
            if (error.message.includes('Pancake: TRANSFER_FAILED')) {
              errorStatus = 'InsufficientFee'
            } else if (error.message.includes('insufficient funds for gas * price + value')) {
              errorStatus = 'InsufficientFee'
            }
          }
          
          updateWalletStatus(wallet.address, errorStatus)
          
          switch(errorStatus) {
            case 'InsufficientFee':
              console.error(`钱包 ${wallet.address} 交易失败: 手续费不足`)
              break;
            case 'InsufficientFee':
              console.error(`钱包 ${wallet.address} 交易失败: Gas 费用不足`)
              break;
            default:
              console.error(`钱包 ${wallet.address} 交易失败: 未知错误`)
          }
        }
      });
  
      await Promise.all(swapPromises);
      
      // 每批处理完后稍作等待两秒
      if (i + batchSize < selectedWalletsForSwap.length) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    }
    
    // 所有交易完成后刷新余额
    await fetchBalances();
  } catch (error) {
    console.error("Bulk swap execution failed:", error);
  } finally {
    setIsExecuting(false);
  }
  }, [selectedWallets, slippageTolerance, inputCurrency, outputCurrency, chainId, library, independentField, addTransaction, setTransactions, updateWalletStatus, fetchBalances, gasPrice, gasIncrease]);

    // 是否有选中的钱包
    const hasSelectedWallets = selectedWallets.some(w => w.selected);
  
    // 是否选择了输入和输出代币
    const hasCurrencies = inputCurrency && outputCurrency;
  
    // 禁用状态
    let buttonText = '批量兑换';
    let isDisabled = false;
  
    if (!hasSelectedWallets) {
      buttonText = '未选择钱包';
      isDisabled = true;
    } else if (!hasCurrencies) {
      buttonText = '请选择代币';
      isDisabled = true;
    }
  
    return (
      <ExecutorContainer>
        {!isExecuting ? (
          <ButtonPrimary onClick={executeBulkSwap} disabled={isDisabled}>
            {buttonText}
          </ButtonPrimary>
        ) : (
          <LoaderContainer>
            <Loader>
              <span className="loader-text">OKOKOK...</span>
            </Loader>
          </LoaderContainer>
        )}
      </ExecutorContainer>
    )
  }