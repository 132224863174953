//侧边导航栏组件 Sidebar.tsx

import React, { useState } from 'react';
import { NavLink,useLocation, } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DollarSign, Briefcase, Tool, Circle, Home, ChevronDown, Send } from 'react-feather';
import { transparentize, getLuminance } from 'polished'
import { CurrencyExchange } from 'react-bootstrap-icons'

const SideNavWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100vh;
  background-color: ${({ theme }) => {
    const isDarkMode = getLuminance(theme.text1) > 0.5;
    return isDarkMode
      ? 'rgba(13, 17, 28, 0.8)'
      : transparentize(0.5, theme.bg1); 
  }};
  backdrop-filter: blur(10px);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: ${({ theme }) => {
    const isDarkMode = getLuminance(theme.text1) > 0.5;
    return isDarkMode
      ? '0 4px 12px rgba(0, 0, 0, 0.2)'
      : '0 4px 12px rgba(0, 0, 0, 0.2)'
  }};
  border-radius: 0 10px 10px 0;

  @media (max-width: 480px) {
    background-color: ${({ theme }) => {
      const isDarkMode = getLuminance(theme.text1) > 0.5;
      return isDarkMode
        ? 'rgba(13, 17, 28, 0.9)'
        : transparentize(0.1, theme.bg1);
    }};
  }
`

const SideNavContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 220px;
  margin: 0 auto;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  &:hover {
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

// 侧边栏标题
const SidebarTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => transparentize(0.9, theme.text3)};
  position: relative;
`
// 导航标头
const TitleText = styled.h2`
  color: ${({ theme }) => theme.text1};
  margin: 0;
  font-size: 1.2rem;
`
// 导航图标
const TitleIcon = styled.span`
  position: absolute;
  left: 0;
`
//
const CategoryTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin-top: 1rem;
`

// 侧边栏大纲图标
const CategoryIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-right: 0.75rem;
  background-color: ${({ theme }) => {
    const isDarkMode = getLuminance(theme.text1) > 0.5;
    return isDarkMode 
      ? 'rgba(39, 117, 182, 0.99)'
      : 'rgba(255, 182, 193, 0.99)';
  }};

  svg {
    color: white;
  }
`

// 侧边栏主标题样式
const CategoryContent = styled.div`
  display: flex;
  align-items: center;
`

const SubNavItem = styled(NavLink)`
  color: ${({ theme }) => {
    const isDarkMode = getLuminance(theme.text1) > 0.5;
    return isDarkMode ? theme.text2 : '#6B7C93';
  }};
  text-decoration: none;
  padding: 0.75rem 0;
  margin: 0.25rem 0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => transparentize(0.7, theme.primary2)};
    color: ${({ theme }) => {
      const isDarkMode = getLuminance(theme.text1) > 0.5;
      return isDarkMode ? theme.text1 : '#4A5568';
    }};
  }

  &.active {
    background-color: ${({ theme }) => transparentize(0.7, theme.primary2)};
    color: ${({ theme }) => {
      const isDarkMode = getLuminance(theme.text1) > 0.5;
      return isDarkMode ? theme.text1 : '#2D3748';
    }};
    font-weight: 600;
  }
`

// 侧边栏子标题 · 样式
const StyledCircle = styled(Circle)<{ active?: boolean }>`
  fill: ${({ theme, active }) => 
    active 
      ? theme.primary1
      : theme.text4
  };
  stroke: none;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
`
const CollapsibleHeader = styled.div`
  color: ${({ theme }) => {
    const isDarkMode = getLuminance(theme.text1) > 0.5;
    return isDarkMode ? theme.text2 : '#6B7C93';
  }};
  text-decoration: none;
  padding: 0.75rem 0;
  margin: 0.25rem 0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.75rem;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: ${({ theme }) => transparentize(0.9, theme.primary2)};
  }
`;
const CollapsibleContent = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  padding-left: 0.5rem;
`;

const ChevronIcon = styled.div<{ isOpen: boolean }>`
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
`;

// 调整子菜单项的样式，确保与父级菜单对齐
const SubNavItemAligned = styled(SubNavItem)`
  padding: 0.5rem 0.75rem;
  margin: 0.25rem 0;
  font-size: 0.95em;
  
  &:first-child {
    margin-top: 0.5rem;
  }
  
  &:last-child {
    margin-bottom: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; //
`;

interface SidebarProps {
  isOpen: boolean;
  toggleNav: () => void;
  onNavItemClick: () => void;
}

export default function Sidebar({ isOpen, onNavItemClick }: SidebarProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const [isTokenIssueOpen, setIsTokenIssueOpen] = useState(false);

  const handleTokenTypeClick = (e: React.MouseEvent) => {
    e.preventDefault(); // 阻止默认导航行为
    setIsTokenIssueOpen(!isTokenIssueOpen);
  };
  
  const handleSubNavClick = () => {
    onNavItemClick();
  };

  const tokenIssueItems = [
    { path: '/StandardToken', label: '标准代币' },
    { path: '/MarketingRebate', label: '营销回流' },
    { path: '/PoolDividends', label: '加池分红' },
    { path: '/HoldingDividends', label: '持币分红' },
    { path: '/NFTDividends', label: 'NFT分红' },
  ];

  return (
    <SideNavWrapper isOpen={isOpen} className="sidebar">
      <SideNavContent>
        <SidebarTitle>
          <TitleIcon><Tool size={18} /></TitleIcon>
          <TitleText>OK Tools</TitleText>
        </SidebarTitle>

        <ContentWrapper>
        <CategoryTitle>
          <CategoryContent>
            <CategoryIcon><Home size={16} /></CategoryIcon>
            {t('首页')}
          </CategoryContent>
        </CategoryTitle>
        <SubNavItem to="/swap" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/swap'} />
          {t('交易')}
        </SubNavItem>
        <SubNavItem to="/pool" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/pool'} />
          {t('流动性池')}
        </SubNavItem>

        <CategoryTitle>
          <CategoryContent>
            <CategoryIcon><CurrencyExchange size={16} /></CategoryIcon>
            {t('交易类')}
          </CategoryContent>
        </CategoryTitle>
        <SubNavItem to="/BulkSwap" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/BulkSwap'} />
          {t('批量交易')}
        </SubNavItem>
        <SubNavItem to="/MarketCap" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/MarketCap'} />
        {t('市值管理')}
        </SubNavItem>
        
        
        <CategoryTitle>
          <CategoryContent>
            <CategoryIcon><Briefcase size={16} /></CategoryIcon>
            {t('钱包相关')}
          </CategoryContent>
        </CategoryTitle>
        <SubNavItem to="/personalized-wallet" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/personalized-wallet'} />
          {t('DIY靓号钱包')}
        </SubNavItem>
        <SubNavItem to="/bulk-wallet-generation" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/bulk-wallet-generation'} />
        {t('批量创建钱包')}
        </SubNavItem>

        <CategoryTitle>
          <CategoryContent>
            <CategoryIcon><Send size={16} /></CategoryIcon>
            {t('发行代币')}
          </CategoryContent>
        </CategoryTitle>
        
        <CollapsibleHeader 
          as="div" 
          onClick={handleTokenTypeClick}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledCircle size={6} />
            {t('代币类型')}
          </div>
          <ChevronIcon isOpen={isTokenIssueOpen}>
            <ChevronDown size={16} />
          </ChevronIcon>
        </CollapsibleHeader>
        
        <CollapsibleContent isOpen={isTokenIssueOpen}>
          {tokenIssueItems.map((item) => (
            <SubNavItemAligned
              key={item.path}
              to={item.path}
              onClick={handleSubNavClick}
            >
              <StyledCircle size={6} active={location.pathname === item.path} />
              {t(item.label)}
            </SubNavItemAligned>
          ))}
        </CollapsibleContent>
        
        <CategoryTitle>
          <CategoryContent>
            <CategoryIcon><DollarSign size={16} /></CategoryIcon>
            {t('代币相关')}
          </CategoryContent>
        </CategoryTitle>
        <SubNavItem to="/token-aggregation" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/token-aggregation'} />
          {t('代币归集')}
        </SubNavItem>
        <SubNavItem to="/multi-sender" onClick={handleSubNavClick}>
        <StyledCircle size={6} active={location.pathname === '/multi-sender'} />
        {t('批量发送')}
        </SubNavItem>
        </ContentWrapper>
      </SideNavContent>
    </SideNavWrapper>
  )
}