//imprortwalletmodal.tsx

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import Modal from '../../components/Modal'
import { ButtonPrimary } from '../../components/Button'
import { WalletType } from './types'

const ModalContent = styled.div`
  padding: 10px;
  width: 420px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg1};
`

const ModalTitle = styled.h2`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text1};
  text-align: center;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
`

const CancelButton = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.red1};
  margin-bottom: 1rem;
`

const WarningContainer = styled.div`
  margin-bottom: 1rem;
`

const WarningMessage = styled.p`
  color: ${({ theme }) => theme.yellow2};
  margin-bottom: 0.5rem;
`

interface ImportWalletModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onImport: (wallets: { address: string; privateKey: string; walletType: WalletType }[]) => void;
  isWalletExisting: (address: string) => boolean;
  expectedCount?: number; // B钱包导入时需要的数量
  walletType: WalletType; // 当前导入的钱包类型
}

export default function ImportWalletModal({ 
  isOpen, 
  onDismiss, 
  onImport, 
  isWalletExisting,
  expectedCount,
  walletType 
}: ImportWalletModalProps) {
  const [privateKeys, setPrivateKeys] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [warnings, setWarnings] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setPrivateKeys('')
      setError(null)
      setWarnings([])
      setIsLoading(false)
    }
  }, [isOpen])

  const handleImport = async () => {
    if (!privateKeys.trim()) {
      setError('请输入私钥')
      return
    }

    setIsLoading(true)
    setError(null)
    setWarnings([])

    try {
      const keys = privateKeys.split('\n').filter(key => key.trim() !== '')
      
      // 检查B钱包数量是否匹配
      if (walletType === 'B' && expectedCount !== undefined && keys.length !== expectedCount) {
        throw new Error(`需要导入 ${expectedCount} 个B钱包以匹配A钱包数量`)
      }

      const uniqueWallets = new Map()
      const newWarnings: string[] = []

      for (const key of keys) {
        let processedKey = key.trim()
        if (!processedKey.startsWith('0x')) {
          processedKey = '0x' + processedKey
        }

        try {
          const wallet = new ethers.Wallet(processedKey)
          const address = wallet.address.toLowerCase()

          if (isWalletExisting(address)) {
            newWarnings.push(`钱包地址 ${address} 已存在，将被跳过。`)
          } else if (uniqueWallets.has(address)) {
            newWarnings.push(`重复的私钥， ${address}。已导入其中一个`)
          } else {
            uniqueWallets.set(address, { 
              address: wallet.address, 
              privateKey: processedKey,
              walletType
            })
          }
        } catch (error) {
          console.error('Invalid private key:', processedKey)
          newWarnings.push(`无效的私钥: ${processedKey.slice(0, 10)}...`)
        }
      }

      const wallets = Array.from(uniqueWallets.values())
      
      if (wallets.length === 0) {
        throw new Error('没有有效的钱包可导入')
      }

      setWarnings(newWarnings)
      await onImport(wallets)
      
      if (newWarnings.length > 0) {
        setError('部分钱包导入成功，部分私钥无效或重复。')
      } else {
        onDismiss()
      }
    } catch (error) {
      setError('导入钱包失败: ' + (error instanceof Error ? error.message : String(error)))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={false} maxHeight={90}>
      <ModalContent>
        <ModalTitle>导入{walletType === 'A' ? 'A组' : 'B组'}钱包</ModalTitle>
        {walletType === 'B' && expectedCount && (
          <WarningMessage>请导入 {expectedCount} 个B钱包以匹配A钱包数量</WarningMessage>
        )}
        <StyledTextArea
          value={privateKeys}
          onChange={(e) => {
            setPrivateKeys(e.target.value)
            setError(null)
            setWarnings([])
          }}
          placeholder={`请输入${walletType}组钱包私钥，每行一个`}
          disabled={isLoading}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {warnings.length > 0 && (
          <WarningContainer>
            {warnings.map((warning, index) => (
              <WarningMessage key={index}>{warning}</WarningMessage>
            ))}
          </WarningContainer>
        )}
        <ButtonContainer>
          <CancelButton onClick={onDismiss} disabled={isLoading}>取消</CancelButton>
          <ButtonPrimary onClick={handleImport} disabled={isLoading}>
            {isLoading ? '导入中...' : '导入钱包'}
          </ButtonPrimary>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}