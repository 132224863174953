//bulk swap panel

import React, { useState, useEffect, useCallback, useReducer } from 'react'
import styled from 'styled-components'
import { ButtonConfirmed} from '../../components/Button'
import ImportWalletModal from './ImportWalletModal'
import { useActiveWeb3React } from '../../hooks'
import { Field } from '../../state/swap/actions'
import { Currency, Token } from 'swap-supproted-multichain-sdk'
import { ethers } from 'ethers'
import { getTokenPrice } from './tokenPriceQuery'
import { WalletStatus, WalletType, WalletPair, Wallet } from './types'
import { FaTrashAlt } from 'react-icons/fa'
import { 
  IoCheckmarkCircle,
} from 'react-icons/io5'


const TopActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  @media (max-width: 480px) {
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem;
  }
`

const ImportButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  @media (max-width: 480px) {
    grid-column: 1 / -1;
    order: -1;
    width: 100%;
  }
`

const ImportButton = styled(ButtonConfirmed)<{ 
  walletType: 'A' | 'B';
  $active?: boolean;
  $imported?: boolean;
}>`
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  min-width: 120px;
  border: none;
  transition: all 0.2s ease;
  
  /* 根据导入状态和钱包类型确定背景色 */
  background: ${({ $active, $imported, walletType }) => {
    // 已导入状态显示绿色
    if ($imported) {
      return 'linear-gradient(90deg, #2ba55f 0%, #2ba55f 100%)';
    }
    // B组特殊处理：未激活时显示灰色
    if (walletType === 'B' && !$active) {
      return 'linear-gradient(90deg, #c4c4c1 0%, #c4c4c1 100%)';
    }
    // 激活但未导入状态显示对应颜色
    return walletType === 'A' 
      ? 'linear-gradient(90deg, #2fba6a 0%, #2fba6a 100%)'
      : 'linear-gradient(90deg, #2fba6a 0%, #2fba6a 100%)';
  }};

  opacity: 1;
  pointer-events: ${({ $active }) => $active ? 'auto' : 'none'};
  color: white;
  cursor: ${({ $active }) => $active ? 'pointer' : 'not-allowed'};

  &:hover {
    transform: ${({ $active, $imported }) => ($active && !$imported) ? 'translateY(-1px)' : 'none'};
    box-shadow: ${({ $active, $imported }) => ($active && !$imported) ? '0 2px 8px rgba(0, 0, 0, 0.1)' : 'none'};
  }

  @media (max-width: 480px) {
    flex: 1;
    padding: 0.5rem;
    font-size: 14px;
  }
`

const DeleteButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.red1};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.bg3};
    transform: translateY(-1px);
  }

  @media (max-width: 480px) {
    width: 36px;
    height: 36px;
  }
`


const PanelContainer = styled.div`
  width: auto;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 0.5rem;
`

const WalletListFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.bg2};
  border-top: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.green1};
  font-size: 15px;
  width: 100%; // 确保footer占满整个宽度
`

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem; // 这会在两个span之间添加间隔
`


const TableContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`

const WalletListContainer = styled.div`
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
`

const WalletTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  font-weight: normal;
`

const StatusText = styled.span<{ status: WalletStatus }>`
  color: ${({ theme, status }) => 
    status === 'Approving' ? theme.primary3 :
    status === 'Buying' ? theme.yellow2 :
    status === 'TransferringToB' ? theme.blue1 :
    status === 'Selling' ? theme.yellow2 :
    status === 'TransferringToA' ? theme.blue1 :
    status === 'Completed' ? theme.green1 :
    status === 'Failed' ? theme.red1 :
    status === 'InsufficientFee' ? theme.red1 :
    theme.text1
  };
`

const ArrowIcon = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 1.2rem;
  padding: 0 0.5rem;
`


const ErrorText = styled.span`
  color: ${({ theme }) => theme.red1};
  font-size: 12px;
  margin-left: 8px;
`


const SettingsContainer = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 12px;
  padding: 1.5rem;
`

const SettingsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const BottomSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.bg3};

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`

const SettingItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const SettingLabel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
`

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Slider = styled.input`
  flex: 1;
  height: 2px;
  background: ${({ theme }) => theme.primary2};
  appearance: none;
  
  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 1px solid ${({ theme }) => theme.bg5};
    cursor: pointer;
  }
`

const SliderValue = styled.span`
  min-width: 45px;
  color: ${({ theme }) => theme.primary1};
`

const RangeGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const RangeInput = styled.input`
  width: 60px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 6px;
  text-align: center;
`

const RangeSeparator = styled.span`
  color: ${({ theme }) => theme.text3};
`

const FullWidthInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 6px;
`
const CheckIcon = styled(IoCheckmarkCircle)`
  margin-left: 6px;
  font-size: 24px;
  vertical-align: -1px; // 微调图标的垂直对齐
`

const translateStatus = (status: WalletStatus): string => {
  switch (status) {
    case 'InsufficientFee':
      return '费用不足';
    case 'Pending':
      return '等待中';
    case 'Approving':
      return '授权中';
    case 'Buying':
      return '买入中';
    case 'TransferringToB':
      return '转账→ B';
    case 'Selling':
      return '卖出中';
    case 'TransferringToA':
      return '转账→ A';
    case 'Completed':
      return '已完成';
    case 'Failed':
      return '失败';
      case 'InsufficientFee':
      return 'Gas费用不足';
    case 'InsufficientBalance':
      return '余额不足';
    case 'SwapFailed':
      return '交易失败';
    case 'InvariantFailed':
      return '流动性不足';
    default:
      return status;
  }
};

interface BulkSwapPanelProps {
  inputCurrency: Currency | undefined
  outputCurrency: Currency | undefined
  independentField: Field
  setSlippageTolerance: React.Dispatch<React.SetStateAction<number>>
  slippageTolerance: number
  setTransactions: React.Dispatch<React.SetStateAction<{ hash: string; address: string }[]>>
  currentGasPrice: string
  gasIncrease: number
  setGasIncrease: React.Dispatch<React.SetStateAction<number>>
  tradingDelay: number
  setTradingDelay: React.Dispatch<React.SetStateAction<number>>
  walletPairs: WalletPair[]
  setWalletPairs: React.Dispatch<React.SetStateAction<WalletPair[]>>
  buyPercentageRange: { min: number; max: number }
  setBuyPercentageRange: React.Dispatch<React.SetStateAction<{ min: number; max: number }>>
  sellPercentageRange: { min: number; max: number }
  setSellPercentageRange: React.Dispatch<React.SetStateAction<{ min: number; max: number }>>
  targetVolume: number
  setTargetVolume: React.Dispatch<React.SetStateAction<number>>
  onTradingVolumeChange?: (volume: string) => void
}


type WalletPairAction = 
  | { type: 'ADD_WALLET_A'; payload: { address: string; privateKey: string; walletType: WalletType }[] }
  | { type: 'ADD_WALLET_B'; payload: { address: string; privateKey: string; walletType: WalletType }[] }
  | { type: 'UPDATE_PAIR_STATUS'; payload: { pairId: number; status: WalletStatus; txHash?: string } }
  | { type: 'UPDATE_TRADING_VOLUME'; payload: { pairId: number; amount: string } }
  | { type: 'TOGGLE_PAIR_SELECTION'; payload: number }
  | { type: 'TOGGLE_SELECT_ALL'; payload: boolean }
  | { type: 'DELETE_SELECTED_PAIRS' }

  function walletPairReducer(state: WalletPair[], action: WalletPairAction): WalletPair[] {
    switch (action.type) {
      case 'ADD_WALLET_A':
        return [
          ...state,
          ...action.payload.map((wallet, index) => ({
            walletA: {
              id: Date.now() + index,
              ...wallet,
              balance: '0',
              selected: false,
              status: 'Pending' as WalletStatus, // 明确指定类型
              swapPercent: Math.floor(Math.random() * 81) + 10,
              tradingVolume: '0',
              pairId: Date.now() + index
            },
            walletB: null,
            pairId: Date.now() + index,
            tradingVolume: '0'
          }))
        ]
      case 'ADD_WALLET_B':
        return state.map((pair, index) => {
          if (!pair.walletB && action.payload[index]) {
            return {
              ...pair,
              walletB: {
                id: Date.now() + index,
                ...action.payload[index],
                balance: '0',
                selected: pair.walletA.selected,
                status: 'Pending' as WalletStatus, // 明确指定类型
                swapPercent: Math.floor(Math.random() * 81) + 10,
                tradingVolume: '0',
                pairId: pair.pairId
              }
            }
          }
          return pair
        })
        case 'UPDATE_PAIR_STATUS':
          return state.map(pair => {
            if (pair.pairId === action.payload.pairId) {
              // 判断是否是 B 钱包相关的状态
              const isWalletBStatus = [
                'Approving',
                'Selling',
                'TransferringToA',
                'Completed',
                'Failed',
                'InsufficientFee'
              ].includes(action.payload.status);
        
              return {
                ...pair,
                walletA: { 
                  ...pair.walletA,
                  status: !isWalletBStatus ? action.payload.status : pair.walletA.status,
                  txHash: !isWalletBStatus ? action.payload.txHash : pair.walletA.txHash
                },
                walletB: pair.walletB ? {
                  ...pair.walletB,
                  status: isWalletBStatus ? action.payload.status : pair.walletB.status,
                  txHash: isWalletBStatus ? action.payload.txHash : pair.walletB.txHash
                } : null
              };
            }
            return pair;
          });
    case 'UPDATE_TRADING_VOLUME':
      return state.map(pair => 
        pair.pairId === action.payload.pairId
          ? {
              ...pair,
              tradingVolume: (parseFloat(pair.tradingVolume) + parseFloat(action.payload.amount)).toFixed(2)
            }
          : pair
      )
      case 'TOGGLE_PAIR_SELECTION':
        const newState = state.map(pair => {
          if (pair.pairId === action.payload) {
            console.log('Toggling pair:', {
              pairId: pair.pairId,
              beforeSelect: pair.walletA.selected,
              afterSelect: !pair.walletA.selected
            });
            return {
              ...pair,
              walletA: {
                ...pair.walletA,
                selected: !pair.walletA.selected
              },
              walletB: pair.walletB ? {
                ...pair.walletB,
                selected: !pair.walletA.selected
              } : null
            };
          }
          return pair;
        });
        console.log('New state after toggle:', newState);
        return newState;
        
        case 'TOGGLE_SELECT_ALL':
          return state.map(pair => ({
            ...pair,
            walletA: {
              ...pair.walletA,
              selected: action.payload
            },
            walletB: pair.walletB ? {
              ...pair.walletB,
              selected: action.payload
            } : null
          }));
    case 'DELETE_SELECTED_PAIRS':
      return state.filter(pair => !pair.walletA.selected)
    default:
      return state
  }
}

export default function BulkSwapPanel({
  inputCurrency,
  outputCurrency,
  setTransactions,
  setSlippageTolerance,
  targetVolume,
  setTargetVolume,
  slippageTolerance,
  currentGasPrice,
  gasIncrease,
  walletPairs,
  setWalletPairs,
  setGasIncrease,
  tradingDelay,
  buyPercentageRange,
  setBuyPercentageRange,
  sellPercentageRange,
  onTradingVolumeChange,
  setSellPercentageRange,
  setTradingDelay
}: BulkSwapPanelProps) {
  const { chainId } = useActiveWeb3React()
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [currentWalletType, setCurrentWalletType] = useState<WalletType>('A')
  const [totalTradingVolume, setTotalTradingVolume] = useState('0')

  const [selectAll, setSelectAll] = useState(false)
  const [state, dispatch] = useReducer(walletPairReducer, walletPairs);

  //买入卖出百分比范围
  // 本地状态
  const [buyInputValues, setBuyInputValues] = useState({
    min: buyPercentageRange.min.toString(),
    max: buyPercentageRange.max.toString()
  });

  const [sellInputValues, setSellInputValues] = useState({
    min: sellPercentageRange.min.toString(),
    max: sellPercentageRange.max.toString()
  });

  // 同步父组件的状态变化到输入值
  useEffect(() => {
    setBuyInputValues({
      min: buyPercentageRange.min.toString(),
      max: buyPercentageRange.max.toString()
    });
  }, [buyPercentageRange]);

  useEffect(() => {
    setSellInputValues({
      min: sellPercentageRange.min.toString(),
      max: sellPercentageRange.max.toString()
    });
  }, [sellPercentageRange]);


  // 同步 state
useEffect(() => {
  setWalletPairs(state);
}, [state, setWalletPairs]);

// 修改 useEffect 中的类型判断
useEffect(() => {
  if (!chainId || !inputCurrency) return
  
  const tokenAddress = inputCurrency instanceof Token 
    ? inputCurrency.address 
    : ethers.constants.AddressZero

  if (typeof tokenAddress === 'string') {
    getTokenPrice(tokenAddress, chainId.toString())
      .then(price => {
        const newTotal = walletPairs.reduce((sum, pair) => 
          sum + parseFloat(pair.tradingVolume), 0
        ).toFixed(2)
        setTotalTradingVolume(newTotal)
      })
  }
}, [chainId, inputCurrency, walletPairs])

const isToken = (currency: Currency | undefined): currency is Token => {
  return currency instanceof Token
}

useEffect(() => {
  if (!chainId || !inputCurrency) return
  
  const tokenAddress = isToken(inputCurrency) 
    ? inputCurrency.address 
    : ethers.constants.AddressZero

  if (typeof tokenAddress === 'string') {
    getTokenPrice(tokenAddress, chainId.toString())
      .then(price => {
        const newTotal = walletPairs.reduce((sum, pair) => 
          sum + parseFloat(pair.tradingVolume), 0
        ).toFixed(2)
        setTotalTradingVolume(newTotal)
      })
  }
}, [chainId, inputCurrency, walletPairs])

//计算总交易量
useEffect(() => {
  const calculateTotalVolume = () => {
    const total = walletPairs.reduce((sum, pair) => 
      sum + parseFloat(pair.tradingVolume || '0'), 0
    );
    const formattedTotal = total.toFixed(2);
    setTotalTradingVolume(formattedTotal);
    
    // 调用回调函数，将总交易量传递给父组件
    if (onTradingVolumeChange) {
      onTradingVolumeChange(formattedTotal);
    }
  };

  calculateTotalVolume();
}, [walletPairs, onTradingVolumeChange]);


const handleImportWallets = useCallback((newWallets: { address: string; privateKey: string; walletType: WalletType }[]) => {
  if (currentWalletType === 'A') {
    const newPairs = newWallets.map((wallet, index) => {
      const pairId = Date.now() + index;
      return {
        pairId,
        walletA: {
          id: Date.now() + index,
          ...wallet,
          selected: false,
          status: 'Pending' as WalletStatus,
          balance: '0',
          swapPercent: Math.floor(Math.random() * 81) + 10,
          tradingVolume: '0',
          pairId
        },
        walletB: null,
        tradingVolume: '0'
      } as WalletPair;
    });
    setWalletPairs(prev => [...prev, ...newPairs]);
    setCurrentWalletType('B'); // 自动切换到B组
  } else {
    setWalletPairs(prev => prev.map((pair, index) => {
      if (!pair.walletB && newWallets[index]) {
        const walletB: Wallet = {
          id: Date.now() + index,
          ...newWallets[index],
          selected: pair.walletA.selected,
          status: 'Pending' as WalletStatus,
          balance: '0',
          swapPercent: Math.floor(Math.random() * 81) + 10,
          tradingVolume: '0',
          pairId: pair.pairId
        };
        return {
          ...pair,
          walletB
        };
      }
      return pair;
    }));
    setCurrentWalletType('A'); // 完成后切换回A组
  }
}, [currentWalletType, setWalletPairs]);

  const isWalletExisting = useCallback((address: string): boolean => {
    return walletPairs.some(pair => 
      (pair.walletA.address.toLowerCase() === address.toLowerCase()) ||
      (pair.walletB?.address.toLowerCase() === address.toLowerCase())
    )
  }, [walletPairs])

  const toggleSelectAll = useCallback(() => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setWalletPairs(prevPairs => 
      prevPairs.map(pair => ({
        ...pair,
        walletA: {
          ...pair.walletA,
          selected: newSelectAll
        },
        walletB: pair.walletB ? {
          ...pair.walletB,
          selected: newSelectAll
        } : null
      }))
    );
  }, [selectAll, setWalletPairs]);

  const togglePairSelection = useCallback((pairId: number) => {
    console.log('Toggling selection for pair:', pairId);
    setWalletPairs(prevPairs => 
      prevPairs.map(pair => {
        if (pair.pairId === pairId) {
          return {
            ...pair,
            walletA: {
              ...pair.walletA,
              selected: !pair.walletA.selected
            },
            walletB: pair.walletB ? {
              ...pair.walletB,
              selected: !pair.walletA.selected
            } : null
          };
        }
        return pair;
      })
    );
  }, [setWalletPairs]);

  const deleteSelectedPairs = useCallback(() => {
    dispatch({ type: 'DELETE_SELECTED_PAIRS' })
    setSelectAll(false)
  }, [])


  const handleGasIncreaseChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) {
      setGasIncrease(value)
    }
  }, [setGasIncrease])

  const renderPairRow = useCallback((pair: WalletPair) => {
    const shortenAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`;
    return (
      <tr key={pair.pairId}>
        <td>
          <input 
            type="checkbox" 
            checked={pair.walletA.selected}
            onChange={() => togglePairSelection(pair.pairId)}
          />
        </td>
        <td>{shortenAddress(pair.walletA.address)}</td>
        <td>{pair.walletB ? shortenAddress(pair.walletB.address) : '-'}</td>
        <td>${pair.tradingVolume}</td>
        <td>
          <StatusText status={pair.walletA.status}>
            {translateStatus(pair.walletA.status)}
          </StatusText>
          {pair.walletB && pair.walletB.status !== 'Pending' && (
            <>
              {' -> '}
              <StatusText status={pair.walletB.status}>
                {translateStatus(pair.walletB.status)}
              </StatusText>
            </>
          )}
        </td>
      </tr>
    );
  }, [togglePairSelection]);

      // 本地状态
      const [localSlippageTolerance, setLocalSlippageTolerance] = useState<string>(
        slippageTolerance.toString()
      );
      const [localTargetVolume, setLocalTargetVolume] = useState<string>(
        targetVolume.toString()
      );
    
      // 处理滑点变化
      const handleSlippageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalSlippageTolerance(value);
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          setSlippageTolerance(numValue);
        }
      }, [setSlippageTolerance]);
    
      // 处理目标交易量变化
      const handleTargetVolumeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalTargetVolume(value);
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          setTargetVolume(numValue);
        }
      }, [setTargetVolume]);

        // 同步props中的目标交易量
        useEffect(() => {
          setLocalTargetVolume(targetVolume.toString());
        }, [targetVolume]);

      // 钱包导入检查
  const hasWalletA = walletPairs.length > 0;
  const allWalletBImported = walletPairs.every(pair => pair.walletB);
  // 更新按钮文字逻辑
  const getButtonText = (type: 'A' | 'B') => {
    if (type === 'A') {
      return hasWalletA ? (
        <>
          Done<CheckIcon />
        </>
      ) : 'A组钱包';
    } else {
      if (!hasWalletA) {
        return 'B组钱包';
      }
      return allWalletBImported ? (
        <>
          Done<CheckIcon />
        </>
      ) : 'B组导入';
    }
  };
    // 判断是否已导入
    const isImported = (type: 'A' | 'B') => {
      if (type === 'A') {
        return hasWalletA;
      } else {
        // B组在全部导入完成时才算导入完成
        return hasWalletA && allWalletBImported;
      }
    };

  return (
    <PanelContainer>
      <TopActionContainer>
        <ImportButtonsContainer>
          <ImportButton 
            onClick={() => {
              setCurrentWalletType('A');
              setIsImportModalOpen(true);
            }}
            $active={!hasWalletA}
            $imported={isImported('A')}
            walletType="A"
          >
            {getButtonText('A')}
          </ImportButton>
          <ArrowIcon>→</ArrowIcon>
          <ImportButton 
            onClick={() => {
              setCurrentWalletType('B');
              setIsImportModalOpen(true);
            }}
            $active={hasWalletA && !allWalletBImported}
            $imported={isImported('B')}
            walletType="B"
          >
            {getButtonText('B')}
          </ImportButton>
        </ImportButtonsContainer>
        <DeleteButton onClick={deleteSelectedPairs}>
          <FaTrashAlt size={18} />
        </DeleteButton>
      </TopActionContainer>

      <TableContainer>
        <WalletListContainer>
          <WalletTable>
            <TableHeader>
              <tr>
                <TableHeaderCell>
                  <input type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
                </TableHeaderCell>
                <TableHeaderCell>钱包A</TableHeaderCell>
                <TableHeaderCell>钱包B</TableHeaderCell>
                <TableHeaderCell>已刷金额($)</TableHeaderCell>
                <TableHeaderCell>状态</TableHeaderCell>
              </tr>
            </TableHeader>
            <tbody>
              {walletPairs.map(renderPairRow)}
            </tbody>
          </WalletTable>
        </WalletListContainer>
      </TableContainer>

      <WalletListFooter>
        <FooterContent>
          <span>已产生的交易量：${totalTradingVolume}</span>
          <span>钱包组数量：{walletPairs.length}</span>
        </FooterContent>
      </WalletListFooter>

      <SettingsContainer>
  <SettingsLayout>
  <LeftSection>
          {/* 滑点设置 */}
          <SettingItem>
            <SettingLabel>滑点设置 (%)</SettingLabel>
            <SliderContainer>
              <Slider
                type="range"
                min={0.1}
                max={49}
                step={0.1}
                value={localSlippageTolerance}
                onChange={handleSlippageChange}
              />
              <SliderValue>{parseFloat(localSlippageTolerance).toFixed(1)}%</SliderValue>
            </SliderContainer>
          </SettingItem>

      {/* GAS增加 */}
      <SettingItem>
        <SettingLabel>GAS增加 (%)</SettingLabel>
        <SliderContainer>
          <Slider
            type="range"
            min={0}
            max={100}
            value={gasIncrease}
            onChange={handleGasIncreaseChange}
          />
          <SliderValue>{gasIncrease}%</SliderValue>
        </SliderContainer>
      </SettingItem>
    </LeftSection>

    <RightSection>
      {/* 买入比例范围 */}
      <SettingItem>
        <SettingLabel>买入比例范围 (%)</SettingLabel>
        <RangeGroup>
          <RangeInput
            type="text"
            value={buyInputValues.min}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d+$/.test(value)) {
                setBuyInputValues(prev => ({ ...prev, min: value }));
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (value === '' || Number(value) < 10) {
                setBuyInputValues(prev => ({ ...prev, min: '10' }));
                setBuyPercentageRange(prev => ({ ...prev, min: 10 }));
              } else {
                const numValue = Math.min(Number(value), buyPercentageRange.max);
                setBuyInputValues(prev => ({ ...prev, min: numValue.toString() }));
                setBuyPercentageRange(prev => ({ ...prev, min: numValue }));
              }
            }}
          />
          <RangeSeparator>-</RangeSeparator>
          <RangeInput
            type="text"
            value={buyInputValues.max}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d+$/.test(value)) {
                setBuyInputValues(prev => ({ ...prev, max: value }));
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (value === '' || Number(value) > 95) {
                setBuyInputValues(prev => ({ ...prev, max: '95' }));
                setBuyPercentageRange(prev => ({ ...prev, max: 95 }));
              } else {
                const numValue = Math.max(Number(value), buyPercentageRange.min);
                setBuyInputValues(prev => ({ ...prev, max: numValue.toString() }));
                setBuyPercentageRange(prev => ({ ...prev, max: numValue }));
              }
            }}
          />
          {(buyInputValues.min !== '' && Number(buyInputValues.min) < 10) && (
            <ErrorText>最小值不能低于10%</ErrorText>
          )}
          {(buyInputValues.max !== '' && Number(buyInputValues.max) > 95) && (
            <ErrorText>最大值不能超过95%</ErrorText>
          )}
        </RangeGroup>
      </SettingItem>

      {/* 卖出比例范围 */}
      <SettingItem>
        <SettingLabel>卖出比例范围 (%)</SettingLabel>
        <RangeGroup>
          <RangeInput
            type="text"
            value={sellInputValues.min}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d+$/.test(value)) {
                setSellInputValues(prev => ({ ...prev, min: value }));
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (value === '' || Number(value) < 10) {
                setSellInputValues(prev => ({ ...prev, min: '10' }));
                setSellPercentageRange(prev => ({ ...prev, min: 10 }));
              } else {
                const numValue = Math.min(Number(value), sellPercentageRange.max);
                setSellInputValues(prev => ({ ...prev, min: numValue.toString() }));
                setSellPercentageRange(prev => ({ ...prev, min: numValue }));
              }
            }}
          />
          <RangeSeparator>-</RangeSeparator>
          <RangeInput
            type="text"
            value={sellInputValues.max}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d+$/.test(value)) {
                setSellInputValues(prev => ({ ...prev, max: value }));
              }
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (value === '' || Number(value) > 95) {
                setSellInputValues(prev => ({ ...prev, max: '95' }));
                setSellPercentageRange(prev => ({ ...prev, max: 95 }));
              } else {
                const numValue = Math.max(Number(value), sellPercentageRange.min);
                setSellInputValues(prev => ({ ...prev, max: numValue.toString() }));
                setSellPercentageRange(prev => ({ ...prev, max: numValue }));
              }
            }}
          />
          {(sellInputValues.min !== '' && Number(sellInputValues.min) < 10) && (
            <ErrorText>最小值不能低于10%</ErrorText>
          )}
          {(sellInputValues.max !== '' && Number(sellInputValues.max) > 100) && (
            <ErrorText>最大值不能超过100%</ErrorText>
          )}
        </RangeGroup>
      </SettingItem>
    </RightSection>
  </SettingsLayout>

  <BottomSection>
    {/* 每组钱包交易间隔 */}
    <SettingItem>
      <SettingLabel>每组钱包交易间隔 (秒)</SettingLabel>
      <FullWidthInput
        type="number"
        value={tradingDelay}
        onChange={(e) => setTradingDelay(parseInt(e.target.value) || 1)}
      />
    </SettingItem>

          {/* 目标交易量 */}
          <SettingItem>
            <SettingLabel>目标交易量 (USDT)</SettingLabel>
            <FullWidthInput
              type="number"
              min={0}
              value={localTargetVolume}
              onChange={handleTargetVolumeChange}
              onBlur={() => {
                // 在失去焦点时进行额外的验证
                const numValue = parseFloat(localTargetVolume);
                if (isNaN(numValue) || numValue < 0) {
                  setLocalTargetVolume('0');
                  setTargetVolume(0);
                }
              }}
            />
          </SettingItem>
        </BottomSection>
</SettingsContainer>

      <ImportWalletModal
        isOpen={isImportModalOpen}
        onDismiss={() => setIsImportModalOpen(false)}
        onImport={handleImportWallets}
        isWalletExisting={isWalletExisting}
        expectedCount={currentWalletType === 'B' ? walletPairs.filter(p => !p.walletB).length : undefined}
        walletType={currentWalletType}
      />
    </PanelContainer>
  )
}