import { ethers } from 'ethers';

const TOKENS = {
  // Mainnet
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  USDC_ETH: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  USDT_ETH: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  // BSC
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  USDC_BSC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  USDT_BSC: '0x55d398326f99059fF775485246999027B3197955',
  // Sepolia
  WETH_SEPOLIA: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
};

const FACTORY_ADDRESS = {
  ETH: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  BSC: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
};

const ABIS = {
  DEX_PAIR: [
    'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
    'function token0() external view returns (address)'
  ],
  FACTORY: [
    'function getPair(address tokenA, address tokenB) external view returns (address pair)'
  ],
  ERC20: [
    'function decimals() external view returns (uint8)'
  ]
};

const RPC_URLS = {
  '1': `https://mainnet.infura.io/v3/f713df138ba346b7b448ec3525d3ec0b`,
  '56': 'https://bsc-dataseed1.binance.org',
  '11155111': `https://eth-sepolia.g.alchemy.com/v2/DKYYmOFAmNJWkHSsPnaw-pEgurNcTdrG`,
};

const OKX_SYMBOLS = {
  // Mainnet
  [TOKENS.WETH.toLowerCase()]: 'ETH-USDT',
  [ethers.constants.AddressZero.toLowerCase()]: 'ETH-USDT',
  [TOKENS.USDC_ETH.toLowerCase()]: 'USDC-USDT',
  [TOKENS.USDT_ETH.toLowerCase()]: 'USDT-USDC',
  // BSC
  [TOKENS.WBNB.toLowerCase()]: 'BNB-USDT',
  [TOKENS.USDC_BSC.toLowerCase()]: 'USDC-USDT',
  [TOKENS.USDT_BSC.toLowerCase()]: 'USDT-USDC',
  // Sepolia (使用主网价格)
  [TOKENS.WETH_SEPOLIA.toLowerCase()]: 'ETH-USDT',
};

const priceCache: { [key: string]: { price: number, timestamp: number } } = {};
const CACHE_DURATION = 30000; // 30秒缓存

async function getOKXPrice(symbol: string): Promise<number> {
  const cacheKey = `okx_${symbol}`;
  if (priceCache[cacheKey] && Date.now() - priceCache[cacheKey].timestamp < CACHE_DURATION) {
    return priceCache[cacheKey].price;
  }

  try {
    // USDT-USDC 这种反向交易对，获取 USDC-USDT 
    const isReverse = symbol === 'USDT-USDC';
    const actualSymbol = isReverse ? 'USDC-USDT' : symbol;

    const response = await fetch(
      `https://www.okx.com/api/v5/market/ticker?instId=${actualSymbol}`
    );
    const data = await response.json();
    let price = parseFloat(data.data?.[0]?.last || '0');
    
    // 反向交易对，取倒数
    if (isReverse && price !== 0) {
      price = 1 / price;
    }

    priceCache[cacheKey] = { price, timestamp: Date.now() };
    return price;
  } catch (error) {
    console.error('OKX API error:', error);
    return priceCache[cacheKey]?.price || 0;
  }
}

async function getTokenDecimals(tokenAddress: string, provider: ethers.providers.Provider): Promise<number> {
  if (tokenAddress === ethers.constants.AddressZero) return 18;
  const contract = new ethers.Contract(tokenAddress, ABIS.ERC20, provider);
  return await contract.decimals();
}

async function getCustomTokenPrice(
  tokenAddress: string,
  baseTokenAddress: string,
  baseTokenPrice: number,
  provider: ethers.providers.Provider,
  isETHChain: boolean
): Promise<number> {
  try {
    const factory = new ethers.Contract(
      isETHChain ? FACTORY_ADDRESS.ETH : FACTORY_ADDRESS.BSC,
      ABIS.FACTORY,
      provider
    );
    
    const pairAddress = await factory.getPair(tokenAddress, baseTokenAddress);
    if (pairAddress === ethers.constants.AddressZero) return 0;

    const pair = new ethers.Contract(pairAddress, ABIS.DEX_PAIR, provider);
    
    const [token0, reserves, decimals0, decimals1] = await Promise.all([
      pair.token0(),
      pair.getReserves(),
      getTokenDecimals(tokenAddress, provider),
      getTokenDecimals(baseTokenAddress, provider)
    ]);
    
    const [reserve0, reserve1] = reserves;
    const isToken0Custom = token0.toLowerCase() === tokenAddress.toLowerCase();
    
    const reserve0Num = Number(reserve0.toString());
    const reserve1Num = Number(reserve1.toString());
    
    let tokenPrice;
    if (isToken0Custom) {
      const decimalAdjustment = 10 ** (decimals1 - decimals0);
      tokenPrice = (reserve1Num / reserve0Num) * decimalAdjustment;
    } else {
      const decimalAdjustment = 10 ** (decimals0 - decimals1);
      tokenPrice = (reserve0Num / reserve1Num) * decimalAdjustment;
    }

    return tokenPrice * baseTokenPrice;
  } catch (error) {
    console.error('Custom token price error:', error);
    return 0;
  }
}

export async function getTokenPrice(tokenAddress: string, chainId: string): Promise<number> {
  const normalizedAddress = tokenAddress.toLowerCase();
  
  // 检查是否是主流代币是则使用 OKX API
  const okxSymbol = OKX_SYMBOLS[normalizedAddress];
  if (okxSymbol) {
    return await getOKXPrice(okxSymbol);
  }

  // 自定义代币，使用 DEX 价格计算
  const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId as keyof typeof RPC_URLS]);

  // Sepolia testnet
  if (chainId === '11155111') {
    return 0; // Sepolia 自定义代币返回 0
  }

  // Ethereum mainnet
  if (chainId === '1') {
    const wethPrice = await getOKXPrice('ETH-USDT');
    return await getCustomTokenPrice(normalizedAddress, TOKENS.WETH, wethPrice, provider, true);
  }

  // BSC
  if (chainId === '56') {
    const wbnbPrice = await getOKXPrice('BNB-USDT');
    return await getCustomTokenPrice(normalizedAddress, TOKENS.WBNB, wbnbPrice, provider, false);
  }

  return 0;
}