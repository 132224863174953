import { useState, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'

// RPC 节点地址
const RPC_URLS = {
  ETH: `https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
  BSC: `https://bsc-dataseed.binance.org/`
}

// Price fetching constants
const TOKENS = {
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
}

const FACTORY_ADDRESS = {
  ETH: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  BSC: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
}

const ABIS = {
  DEX_PAIR: [
    'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
    'function token0() external view returns (address)'
  ],
  FACTORY: [
    'function getPair(address tokenA, address tokenB) external view returns (address pair)'
  ],
  ERC20: [
    'function decimals() external view returns (uint8)'
  ]
}

const priceCache: { [key: string]: { price: number, timestamp: number } } = {}
const CACHE_DURATION = 30000 // 30 seconds

interface Token {
  address: string
  name: string
  symbol: string
  decimals: number
  isNative: boolean
}

interface Balance {
  tokenBalance: string
  nativeBalance: string
  tokenValue: string
  nativeValue: string
}

const formatToFourDecimals = (value: string): string => {
  const parts = value.split('.')
  if (parts.length === 2) {
    return `${parts[0]}.${parts[1].slice(0, 6)}`
  }
  return value
}

async function getOKXPrice(symbol: string): Promise<number> {
  const cacheKey = `okx_${symbol}`
  if (priceCache[cacheKey] && Date.now() - priceCache[cacheKey].timestamp < CACHE_DURATION) {
    return priceCache[cacheKey].price
  }

  try {
    const response = await fetch(`https://www.okx.com/api/v5/market/ticker?instId=${symbol}`)
    const data = await response.json()
    const price = parseFloat(data.data?.[0]?.last || '0')
    priceCache[cacheKey] = { price, timestamp: Date.now() }
    return price
  } catch (error) {
    console.error('OKX API error:', error)
    return priceCache[cacheKey]?.price || 0
  }
}

async function getTokenDecimals(tokenAddress: string, provider: ethers.providers.Provider): Promise<number> {
  if (tokenAddress === ethers.constants.AddressZero) return 18
  const contract = new ethers.Contract(tokenAddress, ABIS.ERC20, provider)
  return await contract.decimals()
}

async function getCustomTokenPrice(
  tokenAddress: string,
  baseTokenAddress: string,
  baseTokenPrice: number,
  provider: ethers.providers.Provider,
  isETHChain: boolean
): Promise<number> {
  try {
    const factory = new ethers.Contract(
      isETHChain ? FACTORY_ADDRESS.ETH : FACTORY_ADDRESS.BSC,
      ABIS.FACTORY,
      provider
    )
    
    const pairAddress = await factory.getPair(tokenAddress, baseTokenAddress)
    if (pairAddress === ethers.constants.AddressZero) return 0

    const pair = new ethers.Contract(pairAddress, ABIS.DEX_PAIR, provider)
    
    const [token0, reserves, decimals0, decimals1] = await Promise.all([
      pair.token0(),
      pair.getReserves(),
      getTokenDecimals(tokenAddress, provider),
      getTokenDecimals(baseTokenAddress, provider)
    ])
    
    const [reserve0, reserve1] = reserves
    const isToken0Custom = token0.toLowerCase() === tokenAddress.toLowerCase()
    
    const reserve0Num = Number(reserve0.toString())
    const reserve1Num = Number(reserve1.toString())
    
    let tokenPrice
    if (isToken0Custom) {
      const decimalAdjustment = 10 ** (decimals1 - decimals0)
      tokenPrice = (reserve1Num / reserve0Num) * decimalAdjustment
    } else {
      const decimalAdjustment = 10 ** (decimals0 - decimals1)
      tokenPrice = (reserve0Num / reserve1Num) * decimalAdjustment
    }

    return tokenPrice * baseTokenPrice
  } catch (error) {
    console.error('Custom token price error:', error)
    return 0
  }
}

async function fetchTokenPrice(chainId: 'eth' | 'bsc', tokenAddress: string): Promise<number> {
  const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId.toUpperCase() as 'ETH' | 'BSC'])
  const normalizedAddress = tokenAddress.toLowerCase()

  // Handle native tokens
  if (normalizedAddress === 'eth' || normalizedAddress === ethers.constants.AddressZero.toLowerCase()) {
    return await getOKXPrice('ETH-USDT')
  }
  if (normalizedAddress === 'bnb') {
    return await getOKXPrice('BNB-USDT')
  }

  // Handle wrapped native tokens
  if (normalizedAddress === TOKENS.WETH.toLowerCase()) {
    return await getOKXPrice('ETH-USDT')
  }
  if (normalizedAddress === TOKENS.WBNB.toLowerCase()) {
    return await getOKXPrice('BNB-USDT')
  }

  // Handle custom tokens
  if (chainId === 'eth') {
    const wethPrice = await getOKXPrice('ETH-USDT')
    return await getCustomTokenPrice(normalizedAddress, TOKENS.WETH, wethPrice, provider, true)
  } else {
    const wbnbPrice = await getOKXPrice('BNB-USDT')
    return await getCustomTokenPrice(normalizedAddress, TOKENS.WBNB, wbnbPrice, provider, false)
  }
}

export const useChainLogic = () => {
  const [selectedChain, setSelectedChain] = useState<'ETH' | 'BSC'>('ETH')
  const [balances, setBalances] = useState<Record<string, Balance>>({})
  const [tokens, setTokens] = useState<Record<string, Token>>({})
  const [selectedToken, setSelectedToken] = useState('')

  const getProvider = useMemo(() => {
    return new ethers.providers.JsonRpcProvider(RPC_URLS[selectedChain])
  }, [selectedChain])

  const resetChain = (newChain: 'ETH' | 'BSC') => {
    const nativeToken: Token = newChain === 'ETH'
      ? { address: 'ETH', name: 'Ethereum', symbol: 'ETH', decimals: 18, isNative: true }
      : { address: 'BNB', name: 'Binance Coin', symbol: 'BNB', decimals: 18, isNative: true }
    
    setTokens({ [nativeToken.address]: nativeToken })
    setSelectedToken(nativeToken.address)
    setBalances(prevBalances => {
      const newBalances: Record<string, Balance> = {}
      Object.keys(prevBalances).forEach(address => {
        newBalances[address] = { 
          nativeBalance: prevBalances[address]?.nativeBalance || '0',
          tokenBalance: '0',
          nativeValue: '0',
          tokenValue: '0'
        }
      })
      return newBalances
    })
  }

  useEffect(() => {
    resetChain(selectedChain)
  }, [selectedChain])

  const handleChainChange = (newChain: 'ETH' | 'BSC') => {
    setSelectedChain(newChain)
  }

  const addToken = async (contractAddress: string) => {
    const tokenContract = new ethers.Contract(
      contractAddress,
      ['function name() view returns (string)', 'function symbol() view returns (string)', 'function decimals() view returns (uint8)'],
      getProvider
    )

    const [name, symbol, decimals] = await Promise.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.decimals()
    ])

    setTokens(prevTokens => ({
      ...prevTokens,
      [contractAddress]: { address: contractAddress, name, symbol, decimals, isNative: false }
    }))

    setBalances(prevBalances => {
      const newBalances = { ...prevBalances }
      Object.keys(newBalances).forEach(address => {
        newBalances[address] = {
          ...newBalances[address],
          tokenBalance: '0',
          tokenValue: '0'
        }
      })
      return newBalances
    })

    return { name, symbol, decimals }
  }

  const fetchBalances = async (addresses: string[], tokenAddress: string) => {
    const nativeToken = selectedChain === 'ETH' ? 'ETH' : 'BNB'
    const chainId = selectedChain === 'ETH' ? 'eth' : 'bsc'
    console.log(`正在获取 ${nativeToken} 和代币地址: ${tokenAddress} 的余额`)
  
    let nativePrice = 0
    let tokenPrice = 0
    const tokenSymbol = tokens[tokenAddress]?.symbol || 'Unknown'
  
    try {
      nativePrice = await fetchTokenPrice(chainId, nativeToken)
      console.log(`原生代币 (${nativeToken}) 价格: ${nativePrice}`)
    } catch (error) {
      console.warn(`无法获取原生代币 ${nativeToken} 的价格:`, error)
    }
    
    if (tokenAddress !== nativeToken) {
      try {
        tokenPrice = await fetchTokenPrice(chainId, tokenAddress)
        console.log(`代币 (${tokenSymbol}) 价格: ${tokenPrice}`)
      } catch (error) {
        console.warn(`无法获取代币 ${tokenSymbol} 的价格:`, error)
      }
    } else {
      tokenPrice = nativePrice
    }

    const fetchedBalances = await Promise.all(
      addresses.map(async (address) => {
        let tokenBalance = '0'
        let nativeBalance = '0'
  
        nativeBalance = formatToFourDecimals(ethers.utils.formatEther(await getProvider.getBalance(address)))
  
        if (tokenAddress !== nativeToken) {
          const tokenContract = new ethers.Contract(
            tokenAddress,
            ['function balanceOf(address) view returns (uint256)'],
            getProvider
          )
          const balance = await tokenContract.balanceOf(address)
          tokenBalance = formatToFourDecimals(ethers.utils.formatUnits(balance, tokens[tokenAddress].decimals))
        } else {
          tokenBalance = nativeBalance
        }
  
        const nativeValue = nativePrice ? (parseFloat(nativeBalance) * nativePrice).toFixed(2) : 'N/A'
        const tokenValue = tokenPrice ? (parseFloat(tokenBalance) * tokenPrice).toFixed(2) : 'N/A'
  
        if (nativeValue === 'N/A') {
          console.warn(`无法计算原生代币 ${nativeToken} 的价值`)
        }
        if (tokenValue === 'N/A' && tokenAddress !== nativeToken) {
          console.warn(`无法计算代币 ${tokenSymbol} 的价值`)
        }
  
        return { 
          address, 
          balance: { 
            tokenBalance, 
            nativeBalance, 
            tokenValue, 
            nativeValue 
          } 
        }
      })
    )
  
    setBalances(prevBalances => {
      const newBalances = { ...prevBalances }
      fetchedBalances.forEach(({ address, balance }) => {
        newBalances[address] = balance
      })
      return newBalances
    })
  }

  return {
    selectedChain,
    handleChainChange,
    balances,
    fetchBalances,
    addToken,
    tokens,
    selectedToken,
    setSelectedToken,
    getProvider,
    fetchTokenPrice
  }
}