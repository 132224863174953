import React from 'react';
import styled from 'styled-components';

const AddressInputWrapper = styled.input`
  flex-grow: 1;
  height: 2rem;
  padding: 0.3rem;
  border-radius: 8px;
  font-size: 0.875rem;
  
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }
`;

interface AddressInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const AddressInput: React.FC<AddressInputProps> = ({ value, onChange, placeholder }) => {
  return (
    <AddressInputWrapper
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default AddressInput;