import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { ButtonPrimary } from '../Button'

const ModalContent = styled.div`
  padding: 10px;
  width: 420px;
  justify-content: center;
  align-items: center;
`

const ModalTitle = styled.h2`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.5rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.2rem;
    text-align: center;
  }
`

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const CancelButton = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.red1};
  margin-bottom: 1rem;
`

interface AddTokenModalProps {
  isOpen: boolean
  onDismiss: () => void
  onAddToken: (address: string) => Promise<void>
  selectedChain: 'ETH' | 'BSC'
}

const AddTokenModal: React.FC<AddTokenModalProps> = ({ isOpen, onDismiss, onAddToken, selectedChain }) => {
  const [tokenAddress, setTokenAddress] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleAddToken = async () => {
    if (!tokenAddress) {
      setError('请输入代币合约地址')
      return
    }
    setError(null)
    setIsLoading(true)
    try {
      await onAddToken(tokenAddress)
      setTokenAddress('')
      onDismiss()
    } catch (error) {
      if (error instanceof Error) {
        setError('添加代币失败: ' + error.message)
      } else {
        setError('添加代币失败: 未知错误')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={false} maxHeight={90}>
      <ModalContent>
        <ModalTitle>添加{selectedChain}代币</ModalTitle>
        <StyledInput
          value={tokenAddress}
          onChange={(e) => {
            setTokenAddress(e.target.value)
            setError(null) // Clear error when input changes
          }}
          placeholder="输入代币合约地址"
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonContainer>
          <CancelButton onClick={onDismiss}>取消</CancelButton>
          <ButtonPrimary onClick={handleAddToken} disabled={isLoading}>
            {isLoading ? '添加中...' : '添加代币'}
          </ButtonPrimary>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}

export default AddTokenModal