import React from 'react';
import styled, { keyframes } from 'styled-components';

const loaderAnimation1 = keyframes`
  0%, 100% {
    background-position: 0 -50px, 100% -50px;
  }
  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }
  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }
  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }
  70%, 98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% calc(100% - 16px);
  }
`;

const loaderAnimation2 = keyframes`
  0%, 70% {
    transform: translate(0);
  }
  100% {
    transform: translate(200%);
  }
`;

const LoaderWrapper = styled.div`
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 #c74d4d;
  position: relative;
  clip-path: inset(-40px 0 -5px);

  &:before {
    content: "";
    position: absolute;
    inset: auto calc(50% - 17px) 0;
    height: 50px;
    --g: no-repeat linear-gradient(#d2d3e0 0 0);
    background: var(--g), var(--g), var(--g), var(--g);
    background-size: 16px 14px;
    animation: ${loaderAnimation1} 2s infinite linear, ${loaderAnimation2} 2s infinite linear;
  }
`;

const Loader: React.FC = () => {
  return <LoaderWrapper />;
};

export default Loader;