import React, { useState, ChangeEvent, FormEvent } from 'react'
import styled from 'styled-components'
import { ButtonSecondary, ButtonPrimary } from '../../components/Button'
import { DashLg, PlusLg } from 'react-bootstrap-icons'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import Prism from 'prismjs';
import 'prismjs/components/prism-solidity';
import 'prismjs/themes/prism-dark.css';

const TitleAndControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`;


const Card = styled.div`
  width: 600px;
  margin: 0 auto;
  background: ${({ theme }) => theme.bg1};
  border-radius: 20px;
  padding: 1.25rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);

  @media (max-width: 480px) {
    width: 100%;
  }

`


const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.text2};
`

const Description = styled.p`
  margin: 0.5rem 0 0;
  font-size: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.green1};
  margin-bottom: 2rem;
`


const FormGroup = styled.div`
  margin-bottom: 1rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.text1};
  font-size: 0.875rem;
`

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }
`

const DecimalsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${({ theme }) => theme.bg1};
  border-radius: 12px;
  padding: 0.5rem;
`

const DecimalsButton = styled(ButtonSecondary)`
  padding: 0.5rem;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.text1};
  }

  &:hover:not(:disabled) {
    svg {
      color: ${({ theme }) => theme.primary1};
    }
    background: ${({ theme }) => theme.bg1};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    svg {
      color: ${({ theme }) => theme.text3};
    }
  }
`

const DecimalsInput = styled(Input)`
  text-align: center;
  max-width: 80px;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 8px;
  
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const SubmitButton = styled(ButtonPrimary)`
  width: 70%;
  margin: 1rem auto 0;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  display: block;
`

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`


const BalanceValue = styled.span<{ chainId?: number }>`
  color: ${({ theme, chainId }) => {
    switch (chainId) {
      case 56:  // BSC Mainnet
      case 97:  // BSC Testnet
        return theme.yellow2;
      case 1:   // ETH Mainnet
      case 11155111: // Sepolia
        return theme.blue1;
      default:
        return theme.green1;
    }
  }};
  font-size: 1.2rem;
  font-weight: 600;
`

const PreviewTitle = styled(Title)`
  margin-bottom: 1rem;
  text-align: left;
  color: #D4D4D4; // 浅灰色标题
`


const CodeContent = styled.code`
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  font-size: 0.875rem;
  line-height: 1.5;
  display: block;
  width: 100%;

  @media (max-width: 480px) {
    font-size: 0.75rem;
    line-height: 1.4;
  }

  .token.comment { color: #6A9955; }
  .token.keyword { color: #569CD6; }
  .token.string { color: #CE9178; }
  .token.number { color: #B5CEA8; }
  .token.class-name { color: #4EC9B0; }
  .token.function { color: #DCDCAA; }
  .token.operator { color: #D4D4D4; }
  .token.punctuation { color: #D4D4D4; }
`;




const PreviewCard = styled(Card)`
  flex: 0.7;
  background: #1E1E1E;
  max-width: 600px; // 保持电脑端原有宽度

  @media (max-width: 480px) { // 改用 480px 断点
    max-width: 100%;
    min-width: unset;
    flex: 1;
    margin: 0;
  }
`

const CodePreview = styled.pre`
  background: transparent;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
  width: 100%;
  
  @media (max-width: 480px) {
    padding: 0.75rem;
    font-size: 12px; // 手机端字体调小
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #1E1E1E;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #424242;
    border-radius: 6px;
    border: 3px solid #1E1E1E;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0.5rem;
    gap: 1rem;
    width: 100%;
  }
`


interface FormData {
    tokenName: string
    tokenSymbol: string
    totalSupply: string
    decimals: number
  }

  export default function TokenIssuanceForm() {
    const { account, chainId, library } = useWeb3React();
    const [userNativeBalance, setUserNativeBalance] = useState<string>('0');
    const [formData, setFormData] = useState<FormData>({
      tokenName: '',
      tokenSymbol: '',
      totalSupply: '',
      decimals: 18
    });

    //高亮代码
    const highlightCode = (code: string) => {
        return Prism.highlight(code, Prism.languages.solidity, 'solidity');
      };

    //模拟合约
    const solidityCode = `
    // SPDX-License-Identifier: MIT
    pragma solidity ^0.8.20;
    
    import "@openzeppelin/contracts/token/ERC20/ERC20.sol";
    import "@openzeppelin/contracts/access/Ownable.sol";
    
    contract ${formData.tokenName || 'Token'} is ERC20, Ownable {
        constructor() ERC20("${formData.tokenName || 'Token'}", "${formData.tokenSymbol || 'TKN'}") 
            Ownable(msg.sender) 
        {
            _mint(msg.sender, ${formData.totalSupply || '0'} * 10**${formData.decimals});
        }
    
        function decimals() public pure override returns
         (uint8) {
            return ${formData.decimals};
        }
    }`.trim();


    // 检查链钩子
    React.useEffect(() => {
      if (account && library) {
        library.getBalance(account).then((balance: ethers.BigNumber) => {
          setUserNativeBalance(ethers.utils.formatEther(balance));
        });
      }
    }, [account, library]);

    // 处理表单数据
  
    const handleDecrease = (): void => {
      setFormData(prev => ({
        ...prev,
        decimals: Math.max(8, prev.decimals - 1)
      }))
    }
  
    const handleIncrease = (): void => {
      setFormData(prev => ({
        ...prev,
        decimals: Math.min(18, prev.decimals + 1)
      }))
    }
  
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const { id, value } = e.target
      setFormData(prev => ({
        ...prev,
        [id]: value
      }))
    }
  
    const handleDecimalsChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const value = parseInt(e.target.value)
      if (!isNaN(value)) {
        setFormData(prev => ({
          ...prev,
          decimals: Math.min(18, Math.max(8, value))
        }))
      }
    }
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault()
      console.log('Form submitted:', formData)
    }
  
    return (
        <Wrapper>
          <Card>
            <TitleAndControlsWrapper>
              <Title>标准代币</Title>
              <HeaderControls>
                <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                  {account && userNativeBalance ? (
                    <BalanceWrapper>
                      <BalanceValue chainId={chainId}>
                        {parseFloat(userNativeBalance).toFixed(2)} {
                          chainId === 1 ? "ETH" : 
                          chainId === 97 ? "TBNB" : 
                          chainId === 56 ? "BNB" : 
                          chainId === 11155111 ? "Sepolia" : 
                          "TEST ETH"
                        }
                      </BalanceValue>
                    </BalanceWrapper>
                  ) : null}
                </AccountElement>
              </HeaderControls>
            </TitleAndControlsWrapper>

          <Description>
            标准0%税合约、无税无功能、默认检测全绿
          </Description>

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="tokenName">代币全称</Label>
            <Input 
              id="tokenName" 
              placeholder="Name" 
              value={formData.tokenName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="tokenSymbol">代币简称</Label>
            <Input 
              id="tokenSymbol" 
              placeholder="Symbol" 
              value={formData.tokenSymbol}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="totalSupply">发行量</Label>
            <Input 
              id="totalSupply" 
              type="number" 
              placeholder="420690000000" 
              value={formData.totalSupply}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="decimals">代币精度 / 建议默认18位精度</Label>
            <DecimalsWrapper>
              <DecimalsButton 
                type="button"
                onClick={handleDecrease}
                disabled={formData.decimals <= 8}
              >
                <DashLg />
              </DecimalsButton>
              <DecimalsInput
                id="decimals"
                type="number"
                value={formData.decimals}
                onChange={handleDecimalsChange}
                min={8}
                max={18}
              />
              <DecimalsButton
                type="button"
                onClick={handleIncrease}
                disabled={formData.decimals >= 18}
              >
                <PlusLg />
              </DecimalsButton>
            </DecimalsWrapper>
          </FormGroup>
        <SubmitButton type="submit" disabled={!account}>
          {account ? '创建合约' : '连接钱包'}
        </SubmitButton>
      </form>
    </Card>
    <PreviewCard>
    <PreviewTitle>{`${formData.tokenName || 'Token'}.sol`}</PreviewTitle>
    <CodePreview>
       <CodeContent className="language-solidity" dangerouslySetInnerHTML={{ 
         __html: highlightCode(solidityCode) 
        }} />
       </CodePreview>
      </PreviewCard>
    </Wrapper>
  )
}