import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components'
import { ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import Card from '../../components/Card'
import Modal from '../Modal'
import { StatusType, ResultType } from './types'
import DisclaimerModal from './DisclaimerModal';


const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0.2rem;
  box-sizing: border-box;

  @media (max-width: 480px) {
    width: 100%;
    padding: 0.1rem;
    max-width: 100%;
  }
`;

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.bg1};
  padding: 1rem;
  border-radius: 20px;

  @media (max-width: 480px) {
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
  }
`;

const StyledInput = styled.input`
  flex-grow: 1;
  height: 2rem;
  padding: 0.3rem;
  border-radius: 8px;
  font-size: 0.875rem;
  
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }

`

const ExecuteButton = styled(ButtonPrimary)`
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto;

`

const StatusContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const StatusGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`

const StatusItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StatusLabel = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 0.9rem;

`

const InfoText = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.green1};
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 0.5rem;

`

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 2rem;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
`

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.text1};
  margin-bottom: 1rem;
`

const ModalText = styled.p`
  color: ${({ theme }) => theme.text2};
  margin-bottom: 1rem;
`

const CloseButton = styled(ButtonPrimary)`
  margin-top: 1rem;
`

const Result = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};
  word-break: break-all;
`

const StatusValue = styled.span<{ isRunning?: boolean }>`
  color: ${({ theme, isRunning }) => isRunning ? theme.green1 : theme.text1};
  font-weight: bold;
  font-size: 1rem;
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InputGroup2 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: calc(100% - 110px);

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 0.5rem;
  }
`;


const InputLabel = styled.span`
  width: 110px;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
  white-space: nowrap;

`

const AddressPreviewContent = styled.div`
  font-family: monospace;
  font-size: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  overflow-wrap: break-word;
  width: 100%;
`

const HighlightedText = styled.span`
  color: ${({ theme }) => theme.primary1};
  font-weight: bold;
`

const PlaceholderText = styled.span`
  color: ${({ theme }) => theme.text3};
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`

const Switch = styled.label`
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  width: 7em;
  height: 2.6em;

    @media (max-width: 480px) {
    transform: translateY(-15px);
  }
`

const SwitchInput = styled.input`
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #313033;
  transition: all 0.4s;
  border-radius: 30px;

  ${SwitchInput}:checked + & {
    background-color: #e18de6;
  }

  ${SwitchInput}:focus + & {
    box-shadow: 10px 10px 100px #7f8996;
  }
`

const SliderTitle = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  transition: all 0.4s;
  user-select: none;
  color: #fff;

  ${SwitchInput}:checked + ${Slider} & {
    left: 40%;
  }
`

const SliderBall = styled.span`
  background-color: ${({ theme }) => theme.primary3};
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: absolute;
  left: -1px;
  top: -1px;
  transition: 0.4s;

  ${SwitchInput}:checked + ${Slider} & {
    left: 72%;
    transform: rotate(360deg);
    box-shadow: none;
    outline: 6px solid rgba(255, 255, 255, 0.278);
  }
`

const SliderIcon = styled.span`
  position: absolute;
  top: 58%;
  left: 53%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: 12px;
`

const ProgressBarContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  position: relative;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${({ theme }) => theme.bg3};
  border-radius: 3px;
  overflow: hidden;
`

const ProgressFill = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background-color: ${({ theme }) => theme.primary1};
  transition: width 0.3s ease-in-out;
`

const ProgressTextContainer = styled.div`
  width: 100%;
  position: relative;
  height: 20px;
  margin-top: 5px;
`

const ProgressText = styled.div<{ progress: number }>`
  color: ${({ theme }) => theme.green1};
  font-size: 0.9rem;
  position: absolute;
  top: 0;
  left: ${({ progress }) => `${progress}%`};
  transform: translateX(-50%);
  transition: left 0.3s ease-in-out;
  white-space: nowrap;
`

const CopyButton = styled(ButtonPrimary)`
  margin-top: 1rem;
  margin-right: 1rem;
`


const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`

const ThreadControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`

const ThreadButton = styled.button`
  background-color: ${({ theme }) => theme.primary2};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const ThreadCount = styled.span`
  margin: 0 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
`


const PersonalizedWallet: React.FC = () => {
  const averageSpeedRef = useRef(0);
  const [threadCount, setThreadCount] = useState(4);
  const [mode, setMode] = useState<'prefix' | 'suffix'>('prefix');
  const [input, setInput] = useState('');
  const [result, setResult] = useState<ResultType | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<StatusType>({
    difficulty: 0,
    speed: 0,
    possibility: '0 addr',
    generated: 0,
    status: '等待中',
    progress: 0
  });
  const [workers, setWorkers] = useState<Worker[]>([]);
  const generatedCountRef = useRef(0);
  const lastGeneratedCountRef = useRef(0);
  const updateIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [averageSpeed, setAverageSpeed] = useState(0);
  const speedHistoryRef = useRef<number[]>([]);
  const maxGeneratedRef = useRef(0);

  const [walletType, ] = useState<'hex' | 'letter'>('hex');

  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [copiedText, setCopiedText] = useState('');

  const handleAgree = () => {
    setShowDisclaimer(false);
  };

  // 工作线程
  const createNewWorkers = useCallback(() => {
    const workerCode = `
    importScripts('https://cdn.jsdelivr.net/npm/web3@latest/dist/web3.min.js');

    // 创建 web3 实例
    const web3 = new Web3();

    // 接收线程信息
    self.onmessage = function(e) {
      const { mode, input, workerId, walletType } = e.data;
      const targetInput = input;
      let generated = 0;

      // 生成随机私钥
      function generateRandomPrivateKey() {
        return web3.utils.randomHex(32);
      }

      // 根据私钥生成地址
      function privateKeyToAddress(privateKey) {
        return web3.eth.accounts.privateKeyToAccount(privateKey).address;
      }

      // 检查地址是否符合条件
      function checkAddress(address) {
        const cleanAddress = address.slice(2);
        if (mode === 'prefix') {
          return cleanAddress.startsWith(targetInput);
        } else {
          return cleanAddress.endsWith(targetInput);
        }
      }

      const batchSize = 100; // 每次生成的私钥数量
      const reportInterval = 10; // 报告间隔
      
      while (true) {
        for (let i = 0; i < batchSize; i++) {
          const privateKey = generateRandomPrivateKey();
          const address = privateKeyToAddress(privateKey);
          generated++;

          // 如果地址符合条件，发送结果消息
          if (checkAddress(address)) {
            self.postMessage({ 
              type: 'result', 
              address: address, 
              privateKey: privateKey,
              generated 
            });
            return;
          }
        }
        // 生成的私钥数量
        if (generated % (batchSize * reportInterval) === 0) {
          self.postMessage({ type: 'increment', count: batchSize * reportInterval });
        }
      }
    };
    `;

    const blob = new Blob([workerCode], { type: 'application/javascript' });
    return Array(threadCount).fill(null).map((_, i) => 
      new Worker(URL.createObjectURL(blob))
    );
}, [threadCount]);



  useEffect(() => {
    const newWorkers = createNewWorkers();
    setWorkers(newWorkers);

    return () => {
      newWorkers.forEach(worker => worker.terminate());
    };
  }, [createNewWorkers, threadCount]);

  const updateStatus = useCallback((newStatus: Partial<StatusType>) => {
    setStatus(prev => {
      const updatedStatus = { ...prev, ...newStatus };
      
      if (updatedStatus.generated > maxGeneratedRef.current) {
        maxGeneratedRef.current = updatedStatus.generated;
      }
      
      updatedStatus.generated = maxGeneratedRef.current;

      return updatedStatus;
    });
  }, []);

  useEffect(() => {
    averageSpeedRef.current = averageSpeed;
  }, [averageSpeed]);

  const adjustThreadCount = useCallback((adjustment: number) => {
    setThreadCount(prevCount => Math.max(1, prevCount + adjustment));
  }, []);

  const handleInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    
    let isValid = false;
    let difficulty = 0;
  
    if (walletType === 'hex') {
      isValid = /^[0-9a-zA-Z]*$/.test(newValue) && newValue.length <= 6;
      difficulty = newValue.length > 0 ? 16 ** newValue.length : 0;
    } else if (walletType === 'letter') {
      isValid = /^[a-zA-Z]*$/.test(newValue) && newValue.length <= 6;
      difficulty = newValue.length > 0 ? 26 ** newValue.length : 0;
    }
  
    if (isValid) {
      setInput(newValue);
      updateStatus({
        difficulty,
        possibility: difficulty > 0 ? `${difficulty.toLocaleString()} addresses` : '0 addr',
      });
    }
  }, [updateStatus, walletType]);



  const handleGenerate = useCallback(() => {
    console.log("启用程序");
    
    // 确保之前的所有 workers 都被终止
    workers.forEach(worker => worker.terminate());
    
    // 创建新的 workers
    const newWorkers = createNewWorkers();
    console.log("创建新的线程:", newWorkers.length);
    setWorkers(newWorkers);
  
    setIsGenerating(true);
    setResult(null);
    generatedCountRef.current = 0;
    lastGeneratedCountRef.current = 0;
    maxGeneratedRef.current = 0;
    speedHistoryRef.current = [];
    setAverageSpeed(0);
  
    updateStatus({
      status: '运行中',
      progress: 0,
      speed: 0,
      generated: 0
    });
  
    const totalPossibilities = walletType === 'hex' ? 16 ** input.length : 26 ** input.length;
    let lastUpdateTime = Date.now();
  
    const updateStatusInterval = () => {
      const currentGenerated = generatedCountRef.current;
      const currentTime = Date.now();
      const timeDiff = (currentTime - lastUpdateTime) / 1000;
      const generatedSinceLastUpdate = currentGenerated - lastGeneratedCountRef.current;
      
      const currentSpeed = Math.round(generatedSinceLastUpdate / timeDiff);
      
      speedHistoryRef.current.push(currentSpeed);
      if (speedHistoryRef.current.length > 10) {
        speedHistoryRef.current.shift();
      }
      
      const avgSpeed = Math.round(speedHistoryRef.current.reduce((a, b) => a + b, 0) / speedHistoryRef.current.length);
      setAverageSpeed(avgSpeed);
      
      const progress = Math.min((currentGenerated / totalPossibilities) * 100, 90);
  
      updateStatus({
        speed: avgSpeed,
        generated: currentGenerated,
        progress: progress
      });
  
      lastGeneratedCountRef.current = currentGenerated;
      lastUpdateTime = currentTime;
    };
  
    updateIntervalRef.current = setInterval(updateStatusInterval, 100);
  
    newWorkers.forEach((worker, index) => {
      worker.postMessage({ mode, input, workerId: index, walletType });
  
      worker.onmessage = (e) => {
        if (e.data.type === 'increment') {
          generatedCountRef.current += e.data.count;
        } else if (e.data.type === 'result') {
          if (updateIntervalRef.current) {
            clearInterval(updateIntervalRef.current);
          }
          setResult(e.data);
          updateStatus({ 
            status: '已完成',
            progress: 100,
            generated: Math.max(e.data.generated, maxGeneratedRef.current),
            speed: averageSpeedRef.current // 使用 ref 而不是 state
          });
          setIsGenerating(false);
          setShowModal(true);
          
          // 终止 worker 创建新的 worker
          newWorkers.forEach(w => w.terminate());
          const newestWorkers = createNewWorkers();
          setWorkers(newestWorkers);
        }
      };
    });
  
    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [input, mode, updateStatus, walletType, createNewWorkers, workers]);
  
  const handleStop = useCallback(() => {
    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
      updateIntervalRef.current = null;
    }
    workers.forEach((worker, index) => {
      worker.terminate();
    });
    setWorkers([]);
    setIsGenerating(false);
    updateStatus({
      status: '已停止',
      progress: 0,
      speed: 0,
      generated: 0
    });
    generatedCountRef.current = 0;
    lastGeneratedCountRef.current = 0;
    maxGeneratedRef.current = 0;
    speedHistoryRef.current = [];
    setAverageSpeed(0);
  }, [workers, updateStatus]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText('已复制');
      setTimeout(() => setCopiedText(''), 2000);
    }, (err) => {
      console.error('复制失败: ', err);
    });
  };
  
  const renderAddressPreview = () => {
    const prefix = '0x';
    const middlePart = '5B7b4D0946e70';
    const placeholderLength = 6 - input.length;
    const placeholder = 'X'.repeat(placeholderLength);

    let displayInput = input;

    if (mode === 'prefix') {
      return (
        <>
          {prefix}
          <HighlightedText>{displayInput}</HighlightedText>
          <PlaceholderText>{placeholder}</PlaceholderText>
          {middlePart}
          <PlaceholderText>XXXXXX</PlaceholderText>
        </>
      );
    } else {
      return (
        <>
          {prefix}
          <PlaceholderText>XXXXXX</PlaceholderText>
          {middlePart}
          <PlaceholderText>{placeholder}</PlaceholderText>
          <HighlightedText>{displayInput}</HighlightedText>
        </>
      );
    }
  };
  
  return (
    <Wrapper>
      <StyledCard>
        <AutoColumn gap="20px">
          <StatusContainer>
            <StatusGrid>
              <StatusItem>
                <StatusLabel>难度:</StatusLabel>
                <StatusValue>{status.difficulty.toLocaleString()}</StatusValue>
              </StatusItem>
              <StatusItem>
                <StatusLabel>速度:</StatusLabel>
                <StatusValue>{`${Math.floor(averageSpeed).toLocaleString()} addr/s`}</StatusValue>
              </StatusItem>
              <StatusItem>
                <StatusLabel>可能性:</StatusLabel>
                <StatusValue>{status.possibility}</StatusValue>
              </StatusItem>
              <StatusItem>
                <StatusLabel>已生成:</StatusLabel>
                <StatusValue>{`${status.generated.toLocaleString()} addr`}</StatusValue>
              </StatusItem>
            </StatusGrid>
            <StatusItem style={{ marginTop: '1.5rem' }}>
              <StatusLabel>状态:</StatusLabel>
              <StatusValue isRunning={status.status === '运行中'}>{status.status}</StatusValue>
            </StatusItem>
            <ProgressBarContainer>
              <ProgressBar>
                <ProgressFill progress={status.progress} />
              </ProgressBar>
              <ProgressTextContainer>
                <ProgressText progress={status.progress}>
                  {status.progress.toFixed()}%
                </ProgressText>
              </ProgressTextContainer>
            </ProgressBarContainer>
          </StatusContainer>

          <InputGroup2>
            <InputLabel>钱包设置:</InputLabel>
            <InputWrapper>
              <SwitchContainer>
                <Switch>
                  <SwitchInput 
                    type="checkbox" 
                    checked={mode === 'suffix'}
                    onChange={() => setMode(mode === 'prefix' ? 'suffix' : 'prefix')}
                  />
                  <Slider>
                    <SliderTitle>{mode === 'prefix' ? '前缀' : '后缀'}</SliderTitle>
                    <SliderBall>
                      <SliderIcon>
                        {mode === 'prefix' ? 'P' : 'S'}
                      </SliderIcon>
                    </SliderBall>
                  </Slider>
                </Switch>
              </SwitchContainer>
            </InputWrapper>
          </InputGroup2>

          <InputGroup>
            <InputLabel>靓号模板：</InputLabel>
            <InputWrapper>
              <AddressPreviewContent>
                {renderAddressPreview()}
              </AddressPreviewContent>
            </InputWrapper>
          </InputGroup>

          <InputGroup>
            <InputLabel>靓号模式:</InputLabel>
            <InputWrapper>
              <StyledInput
                value={input}
                onChange={handleInput}
                placeholder={walletType === 'hex' ? "输入想生成的地址" : "输入1-6位字母（区分大小写）"}
                maxLength={6}
              />
            </InputWrapper>
          </InputGroup>

          <ThreadControl>
          <InputLabel>线程数量:</InputLabel>
          <ThreadButton onClick={() => adjustThreadCount(-1)} disabled={threadCount <= 1}>-</ThreadButton>
          <ThreadCount>{threadCount}</ThreadCount>
          <ThreadButton onClick={() => adjustThreadCount(1)}>+</ThreadButton>
          </ThreadControl>

          <InfoText>提示：生成难度随着靓号位数的增加而指数增长，线程数量可根据您电脑的性能进行调整，根据"任务管理器"，CPU占用率测试以调整生成速率</InfoText>

          <ButtonGroup>
          <ExecuteButton 
            onClick={() => {
              if (isGenerating) {
                handleStop();
              } else {
                handleGenerate();
              }
            }}
          >
            {isGenerating ? '停止' : '开始生成'}
          </ExecuteButton>
        </ButtonGroup>
      </AutoColumn>
    </StyledCard>

      <Modal isOpen={showModal} onDismiss={() => setShowModal(false)}>
        <ModalContent>
          <ModalTitle>钱包生成成功</ModalTitle>
          <ModalText>请注意保存以下信息，这些信息仅存在与您本地浏览器中，请妥善保管您的私钥，不要泄露给任何人！：</ModalText>
          <Result>
            <p>地址: {result?.address}</p>
            <p>私钥: {result?.privateKey}</p>
          </Result>
          <CopyButton onClick={() => copyToClipboard(result?.privateKey || '')}>
            {copiedText || '复制私钥'}
          </CopyButton>
          <CloseButton onClick={() => setShowModal(false)}>关闭</CloseButton>
        </ModalContent>
      </Modal>
      <DisclaimerModal isOpen={showDisclaimer} onAgree={handleAgree} />
    </Wrapper>
  )
}

export default PersonalizedWallet