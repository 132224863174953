import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
//import Intro from './Intro/Intro';

// 海报容器 //
const Banner = styled.div`
  width: 95%;
  height: 200px;
  background: ${({ theme }) => theme.bg2 || '#f0f0f0'};
  border-radius: 10px;
  margin: 10px auto 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`

// SWAP容器 //
export const BodyWrapper = styled.div`
  position: relative;
  width: 420px;
  min-height: 695px;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto; // 添加这行来使容器居中

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 420px;
    margin-bottom: 20px;
  `};
`

const Container = styled.div`
  padding: 1rem;
`

// 主体包裹 //
/*
const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: center;
    gap: 20px;
  `};
`
*/

// 介绍部分 //
/*
const IntroWrapper = styled.div`
  width: 420px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    max-width: 420px;
  `};
`
*/

// 海报图片 //尺寸1000x500
const BANNER_IMAGES = [
  '/images/BANNER1.png',
  //'/images/BANNER2.png',
  //'/images/BANNER3.png'
];

function useBannerImages() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % BANNER_IMAGES.length);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  return BANNER_IMAGES[currentImageIndex];
}

export default function AppBody({ children }: { children: React.ReactNode }) {
  const currentBannerImage = useBannerImages();

  return (
    <BodyWrapper>
      <Banner style={{ backgroundImage: `url(${currentBannerImage})` }} />
      <Container>{children}</Container>
    </BodyWrapper>
  );
}