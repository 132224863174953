//bulk swap executor

import React, { useCallback, useState, useEffect, useRef } from 'react'
import { ButtonPrimary } from '../../components/Button'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { getRouterAddress } from '../../constants'
import { Currency, TokenAmount, JSBI, Token, WETH, Percent } from 'swap-supproted-multichain-sdk'
import { ethers } from 'ethers'
import { Field } from '../../state/swap/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { MaxUint256 } from '@ethersproject/constants'
import { WalletStatus, WalletPair } from './types'
import { Web3Provider } from '@ethersproject/providers'
import { getTokenPrice } from './tokenPriceQuery'
import { ConfirmationModal } from './ConfirmationModal'
import TargetVolumeModal from './TargetVolumeModal'

const ExecutorContainer = styled.div`
  margin-top: 1rem;
  width: 70%;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
`

// 合约 ABI 常量
const routerABI = [
  'function swapExactTokensForTokensSupportingFeeOnTransferTokens(uint256 amountIn, uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external',
  'function swapExactETHForTokensSupportingFeeOnTransferTokens(uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external payable',
  'function swapExactTokensForETHSupportingFeeOnTransferTokens(uint256 amountIn, uint256 amountOutMin, address[] calldata path, address to, uint256 deadline) external'
];

const tokenContractABI = [
  'function balanceOf(address) view returns (uint256)',
  'function transfer(address, uint256) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint256)',
  'function approve(address spender, uint256 amount) returns (bool)'
];


interface BulkSwapExecutorProps {
  setWalletPairs: React.Dispatch<React.SetStateAction<WalletPair[]>>
  buyPercentageRange: { min: number; max: number }
  sellPercentageRange: { min: number; max: number }
  walletPairs: WalletPair[]
  inputCurrency: Currency | undefined
  outputCurrency: Currency | undefined
  independentField: Field
  slippageTolerance: number
  setTransactions: React.Dispatch<React.SetStateAction<{ hash: string; address: string }[]>>
  updateWalletStatus: (address: string, status: WalletStatus, txHash?: string) => void
  fetchBalances: () => Promise<void>
  gasPrice: string
  gasIncrease: number
  library: Web3Provider | undefined
  tradingDelay: number
  totalTradingVolume: number
  currentTradingVolume: number;
  targetVolume: number
  onBeforeExecute: () => void;
}

const WBNB = new Token(
  56,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Wrapped BNB'
)

const WBNB_TESTNET = new Token(
  97,
  '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  18,
  'WBNB',
  'Wrapped BNB'
)


function getWrappedNative(chainId: number): Token {
  switch (chainId) {
    case 1:
    case 11155111:
      return WETH[chainId as keyof typeof WETH];
    case 56:
      return WBNB;
    case 97:
      return WBNB_TESTNET;
    default:
      throw new Error(`Unsupported chain ID: ${chainId}`);
  }
}

function hasIsNative(currency: any): currency is { isNative: boolean } {
  return 'isNative' in currency;
}

function hasIsEther(currency: any): currency is { isEther: boolean } {
  return 'isEther' in currency;
}

function isNativeCurrency(currency: Currency): boolean {
  if (hasIsNative(currency) && currency.isNative) return true;
  if (hasIsEther(currency) && currency.isEther) return true;
  const nativeSymbols = ['ETH', 'BNB', 'TBNB', 'SepETH'];
  return nativeSymbols.includes(currency.symbol || '');
}

function ensureToken(currency: Currency | Token | undefined, chainId: number): Token | undefined {
  if (!currency) return undefined;
  if ('address' in currency) return currency as Token;
  if (isNativeCurrency(currency)) {
    return getWrappedNative(chainId);
  }
  return undefined;
}


export default function BulkSwapExecutor({
  walletPairs,
  setWalletPairs,
  inputCurrency,
  outputCurrency,
  independentField,
  fetchBalances,
  updateWalletStatus,
  slippageTolerance,
  setTransactions,
  gasPrice,
  library,
  gasIncrease,
  tradingDelay,
  buyPercentageRange,
  totalTradingVolume,
  onBeforeExecute,
  currentTradingVolume,
  targetVolume,
  sellPercentageRange 
}: BulkSwapExecutorProps) {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [isExecuting, setIsExecuting] = useState(false)
  const [currentDelay, setCurrentDelay] = useState(0)
  const [shouldStop, setShouldStop] = useState(false)
  const [isStoppingPending, setIsStoppingPending] = useState(false);
  const shouldStopRef = useRef(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showTargetVolumeModal, setShowTargetVolumeModal] = useState(false);
  
    // 计算随机BUY/SELL百分比
    const calculateRandomPercentage = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    // 同步 ref 和 state
    useEffect(() => {
    }, [totalTradingVolume, targetVolume, shouldStop]);
  

  // 倒计时
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (currentDelay > 0) {
      timer = setInterval(() => {
        setCurrentDelay(prev => Math.max(0, prev - 1));
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentDelay]);

    //model
    const handleExecuteClick = () => {
      setIsDialogOpen(true);
    };

    //检测用户设定
    const validateSettings = useCallback(() => {
      console.log('Current settings:', {
        slippageTolerance,
        targetVolume,
        buyPercentageRange,
        sellPercentageRange,
        tradingDelay
      });
      return true;
    }, [slippageTolerance, targetVolume, buyPercentageRange, sellPercentageRange, tradingDelay]);
    
    //确保执行时数据正确
    const handleConfirm = async () => {
      if (onBeforeExecute) {
        await onBeforeExecute();
      }
     //确保所有状态更新完成
      await new Promise<void>((resolve) => {
        setIsDialogOpen(false);
        requestAnimationFrame(() => {
          if (validateSettings()) {
            executeBulkSwap();
          }
          resolve();
        });
      });
    };

  const resetWalletStatuses = useCallback(() => {
    walletPairs.forEach(pair => {
      updateWalletStatus(pair.walletA.address, 'Pending');
      if(pair.walletB) {
        updateWalletStatus(pair.walletB.address, 'Pending');
      }
    });
  }, [walletPairs, updateWalletStatus]);

  const calculateAndUpdateTradingVolume = async (
    address: string,
    amount: ethers.BigNumber,
    isNative: boolean,
    setWalletPairs: React.Dispatch<React.SetStateAction<WalletPair[]>>
  ) => {
    if (!chainId) return;
  
    try {
      const nativeTokenAddress = getWrappedNative(chainId).address;
      const nativePrice = await getTokenPrice(nativeTokenAddress, chainId.toString());
      
      if (nativePrice) {
        const etherAmount = parseFloat(ethers.utils.formatEther(amount));
        const usdValue = etherAmount * nativePrice;
        
        setWalletPairs((prevPairs: WalletPair[]) => 
          prevPairs.map((pair: WalletPair) => {
            if (pair.walletA.address === address || (pair.walletB && pair.walletB.address === address)) {
              return {
                ...pair,
                tradingVolume: (parseFloat(pair.tradingVolume) + usdValue).toFixed(2)
              };
            }
            return pair;
          })
        );
      }
    } catch (error) {
      console.error('计算交易量失败:', error);
    }
  };

  useEffect(() => {
    // 执行交易时监控
    if (isExecuting) {
      console.log('交易量监控:', {
        currentTradingVolume,
        targetVolume,
        shouldStop: shouldStopRef.current
      });
  
      // 检查是否达到目标交易量
      if (targetVolume > 0 && currentTradingVolume >= targetVolume) {
        console.log('达到目标交易量，准备停止');
        setShowTargetVolumeModal(true);
        setShouldStop(true);
        shouldStopRef.current = true;
        setIsStoppingPending(true);
      }
    }
  }, [currentTradingVolume, targetVolume, isExecuting]);

  const executeBulkSwap = useCallback(async () => {

    resetWalletStatuses();
    setTransactions([]);
    setIsExecuting(true);
    setShouldStop(false);
    shouldStopRef.current = false

  // 每次交易开始时创建新的 Set
  const skipPairs = new Set<string>();

    try {
      if (!chainId || !library || !inputCurrency || !outputCurrency) return;

      const routerAddress = getRouterAddress(chainId);
      const inputToken = ensureToken(inputCurrency, chainId);
      const outputToken = ensureToken(outputCurrency, chainId);

      const isNativeInput = isNativeCurrency(inputCurrency);

      if (!inputToken || !outputToken) return;

      const router = new ethers.Contract(
        routerAddress,
        routerABI,
        library.getSigner()
      );

      const deadline = Math.floor(Date.now() / 1000) + 1200;

      //获取Gwei
      const getLatestGasPrice = async () => {
        const gasPrice = await library.getGasPrice();
        return ethers.utils.formatUnits(gasPrice, 'gwei');
      };


      const selectedPairs = walletPairs.filter(pair => 
        pair.walletA.selected && pair.walletB !== null
      );
      console.log('Selected pairs:', selectedPairs);
      const batchSize = 1;

      while (!shouldStop && (!targetVolume || currentTradingVolume < targetVolume)) {
      for (let i = 0; i < selectedPairs.length && !shouldStop; i += batchSize) {
        const batch = selectedPairs.slice(i, i + batchSize);
        
        for (const pair of batch) {
          // 检查是否需要跳过这个钱包对
          if (skipPairs.has(pair.walletA.address)) {
            console.log(`跳过钱包对: ${pair.walletA.address}-${pair.walletB?.address}`);
            continue;
          }

          try {
            if (!pair.walletB) {
              console.log(`钱包 ${pair.walletA.address} 没有对应的B钱包，跳过`);
              continue;
            }
                  
            // 获取最新的 gas
            const buyGasPrice = await getLatestGasPrice();
            let adjustedGasPrice = ethers.utils.parseUnits(buyGasPrice, 'gwei')
              .mul(100 + gasIncrease).div(100);
      
            // A钱包买入操作
            const walletASigner = new ethers.Wallet(pair.walletA.privateKey, library);
            updateWalletStatus(pair.walletA.address, 'Approving');

            let currentBalance;
            if (isNativeInput) {
              currentBalance = await library.getBalance(pair.walletA.address);
            } else {
              const tokenContract = new ethers.Contract(
                inputToken.address,
                tokenContractABI,
                library
              );
              currentBalance = await tokenContract.balanceOf(pair.walletA.address);
            }

            const buyPercent = calculateRandomPercentage(buyPercentageRange.min, buyPercentageRange.max);
            let swapAmountRaw = JSBI.divide(
              JSBI.multiply(
                JSBI.BigInt(currentBalance.toString()),
                JSBI.BigInt(buyPercent)
              ),
              JSBI.BigInt(100)
            );

            if (isNativeInput) {
              const estimatedGasCost = adjustedGasPrice.mul(300000);
              const reserveForGas = JSBI.multiply(JSBI.BigInt(estimatedGasCost.toString()), JSBI.BigInt(2));
              swapAmountRaw = JSBI.subtract(swapAmountRaw, reserveForGas);
            }

            const swapAmount = new TokenAmount(inputToken, swapAmountRaw);

            if (JSBI.equal(swapAmount.raw, JSBI.BigInt(0))) {
              console.log(`钱包 ${pair.walletA.address} 的交易金额为0，跳过`);
              continue;
            }

            const path = [inputToken.address, outputToken.address];
            const slippagePercent = new Percent(JSBI.BigInt(Math.floor(slippageTolerance * 100)), JSBI.BigInt(10000));
            const minOut = swapAmount.multiply(JSBI.subtract(JSBI.BigInt(10000), slippagePercent.numerator)).divide(JSBI.BigInt(10000));

            let method, params;
            if (isNativeInput) {
              method = 'swapExactETHForTokensSupportingFeeOnTransferTokens';
              params = [minOut.quotient.toString(), path, pair.walletA.address, deadline];
            } else {
              method = 'swapExactTokensForTokensSupportingFeeOnTransferTokens';
              params = [swapAmount.raw.toString(), minOut.quotient.toString(), path, pair.walletA.address, deadline];
            }

            const routerWithSigner = router.connect(walletASigner);
            const estimatedGasLimit = await routerWithSigner.estimateGas[method](...params, {
              value: isNativeInput ? swapAmount.raw.toString() : '0',
              gasPrice: adjustedGasPrice
            });

            const gasLimit = estimatedGasLimit.add(100000);

            updateWalletStatus(pair.walletA.address, 'Buying');

            const buyTx = await routerWithSigner[method](...params, {
              value: isNativeInput ? swapAmount.raw.toString() : '0',
              gasPrice: adjustedGasPrice,
              gasLimit: gasLimit
            });

            //更新交易量
            await calculateAndUpdateTradingVolume(
              pair.walletA.address,
              ethers.BigNumber.from(swapAmount.raw.toString()),
              isNativeInput,
              setWalletPairs
              );

            updateWalletStatus(pair.walletA.address, 'Buying', buyTx.hash);
            setTransactions(prev => [...prev, { hash: buyTx.hash, address: pair.walletA.address }]);

            addTransaction(buyTx, {
              summary: `钱包 ${pair.walletA.address} 兑换 ${buyPercent}% 的 ${inputCurrency.symbol} 换成 ${outputCurrency.symbol}`
            });

            await buyTx.wait();

            updateWalletStatus(pair.walletA.address, 'TransferringToB');
            

            // A钱包转账到B钱包
            const tokenContract = new ethers.Contract(
              outputToken.address,
              tokenContractABI,
              walletASigner
            );

            const tokenBalance = await tokenContract.balanceOf(pair.walletA.address);

            const estimatedTransferGas = await tokenContract.estimateGas.transfer(pair.walletB.address, tokenBalance);
            const transferGasLimit = estimatedTransferGas.add(100000);

            // 转账前获取最新gas
           const transferGasPrice = await getLatestGasPrice();
           adjustedGasPrice = ethers.utils.parseUnits(transferGasPrice, 'gwei')
           .mul(100 + gasIncrease).div(100);
            
            const transferTx = await tokenContract.transfer(pair.walletB.address, tokenBalance, {
              gasPrice: adjustedGasPrice,
              gasLimit: transferGasLimit 
            });
            
            await transferTx.wait();

            // B钱包卖出操作
            const walletBSigner = new ethers.Wallet(pair.walletB.privateKey, library);
            updateWalletStatus(pair.walletB.address, 'Approving');

            const bTokenBalance = await tokenContract.balanceOf(pair.walletB.address);
            
            const sellPercent = calculateRandomPercentage(sellPercentageRange.min, sellPercentageRange.max);
            const sellAmount = JSBI.divide(
              JSBI.multiply(
                JSBI.BigInt(bTokenBalance.toString()),
                JSBI.BigInt(sellPercent)
              ),
              JSBI.BigInt(100)
            );

            const bTokenContract = new ethers.Contract(
              outputToken.address,
              tokenContractABI,
              walletBSigner
            );
            const bAllowance = await bTokenContract.allowance(pair.walletB.address, routerAddress);
            if (bAllowance.lt(sellAmount.toString())) {
              const approveTx = await bTokenContract.approve(routerAddress, MaxUint256, {
                gasPrice: adjustedGasPrice
              });
              await approveTx.wait();
            }

            updateWalletStatus(pair.walletB.address, 'Selling');

            const beforeSellBalance = await library.getBalance(pair.walletB.address);

            const sellPath = [outputToken.address, inputToken.address];
            const routerB = router.connect(walletBSigner);

            // 卖出前获取最新gas
           const sellGasPrice = await getLatestGasPrice();
           adjustedGasPrice = ethers.utils.parseUnits(sellGasPrice, 'gwei')
            .mul(100 + gasIncrease).div(100);


            const sellTx = await routerB.swapExactTokensForETHSupportingFeeOnTransferTokens(
              sellAmount.toString(),
              0,
              sellPath,
              pair.walletB.address,
              deadline,
              {
                gasPrice: adjustedGasPrice
              }
            );

            await sellTx.wait();


            const afterSellBalance = await library.getBalance(pair.walletB.address);
            const gainedNative = afterSellBalance.sub(beforeSellBalance);

            //更新卖出交易量
            await calculateAndUpdateTradingVolume(
              pair.walletB.address,
              gainedNative,
              true,
              setWalletPairs
            );

            updateWalletStatus(pair.walletB.address, 'TransferringToA');

           // 转账前获取最新gas
           const transferNative = await getLatestGasPrice();
           adjustedGasPrice = ethers.utils.parseUnits(transferNative, 'gwei')
           .mul(100 + gasIncrease).div(100);


            const transferNativeTx = await walletBSigner.sendTransaction({
              to: pair.walletA.address,
              value: gainedNative,
              gasPrice: adjustedGasPrice,
              gasLimit: 21000
            });

            await transferNativeTx.wait();

            updateWalletStatus(pair.walletA.address, 'Completed');
            updateWalletStatus(pair.walletB.address, 'Completed');

            //结束后检查停止
            if (shouldStopRef.current) {
              return;
            }


          } catch (error) {
            console.error(`钱包对 ${pair.walletA.address}-${pair.walletB?.address} 执行失败:`, error);
            
            let errorStatus: WalletStatus = 'Failed';
            if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
              const errorMessage = error.message.toLowerCase();
              
              if (errorMessage.includes('insufficient funds')) {
                errorStatus = 'InsufficientFee';
                skipPairs.add(pair.walletA.address);
              } else if (errorMessage.includes('insufficient balance')) {
                errorStatus = 'InsufficientBalance';
                skipPairs.add(pair.walletA.address);
              } else if (errorMessage.includes('invariant failed') && errorMessage.includes('is not a uint256')) {
                errorStatus = 'InsufficientBalance';
                skipPairs.add(pair.walletA.address);
              } else if (errorMessage.includes('invariant failed')) {
                errorStatus = 'InvariantFailed';
                skipPairs.add(pair.walletA.address);
              }
            }        
            
            updateWalletStatus(pair.walletA.address, errorStatus);
            if (pair.walletB) {
              updateWalletStatus(pair.walletB.address, errorStatus);
            }

            // 检查是否还有可用的钱包对
            const availablePairs = selectedPairs.filter(p => !skipPairs.has(p.walletA.address));
            if (availablePairs.length === 0) {
              console.log('没有可用的钱包对，停止交易');
              setShouldStop(true);
              shouldStopRef.current = true;
              setIsStoppingPending(true);
              return;
            }
          }
        }

        // 批次间等待
        if (i + batchSize < selectedPairs.length && !shouldStop) {
          setCurrentDelay(tradingDelay);
          await new Promise(resolve => setTimeout(resolve, tradingDelay * 1000));
          setCurrentDelay(0);
        }
      }

        // 如果还未达到目标且未停止
        if (!shouldStop && (!targetVolume || currentTradingVolume < targetVolume)) {
          setCurrentDelay(tradingDelay);
          await new Promise(resolve => setTimeout(resolve, tradingDelay * 1000));
          setCurrentDelay(0);
        }
      }
  
      await fetchBalances();
      
    } catch (error) {
      console.error("批量交易执行失败:", error);
    } finally {
      setIsExecuting(false);
      setIsStoppingPending(false);
      setShouldStop(false);
      setCurrentDelay(0);
    }
  }, [
    walletPairs,
    slippageTolerance,
    inputCurrency,
    outputCurrency,
    chainId,
    library,
    independentField,
    addTransaction,
    setTransactions,
    updateWalletStatus,
    fetchBalances,
    gasPrice,
    gasIncrease,
    tradingDelay,
    totalTradingVolume,
    buyPercentageRange,
    sellPercentageRange,
    currentTradingVolume, 
    onBeforeExecute,
    targetVolume,
    shouldStop
  ]);

  const hasSelectedWallets = walletPairs.some(pair => 
    pair.walletA.selected && pair.walletB !== null
  );
  
  const hasCurrencies = inputCurrency && outputCurrency;

  return (
    <>
      <ExecutorContainer>
        <ButtonPrimary 
          onClick={handleExecuteClick}
          disabled={!hasSelectedWallets || !hasCurrencies || isExecuting}
        >
          {isExecuting 
            ? currentDelay > 0 
              ? `等待${currentDelay}秒...` 
              : '执行中...'
            : !hasSelectedWallets 
              ? '未选择钱包'
              : !hasCurrencies 
                ? '请选择代币'
                : '确认交易信息'
          }
        </ButtonPrimary>
        {isExecuting && (
          <ButtonPrimary
            onClick={() => {
              setShouldStop(true)
              shouldStopRef.current = true
              setIsStoppingPending(true)
            }}
            disabled={isStoppingPending}
            style={{ 
              backgroundColor: isStoppingPending ? '#E8E9EA' : '#ff4d4d'
            }}
          >
            {isStoppingPending ? '完成交易后停止' : '停止'}
          </ButtonPrimary>
        )}
      </ExecutorContainer>

      <ConfirmationModal
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirm}
        slippageTolerance={slippageTolerance}
        tradingDelay={tradingDelay}
        targetVolume={targetVolume}
        gasIncrease={gasIncrease}
        buyPercentageRange={buyPercentageRange}
        sellPercentageRange={sellPercentageRange}
      />
    <TargetVolumeModal
      isOpen={showTargetVolumeModal}
      onClose={() => setShowTargetVolumeModal(false)}
      currentTradingVolume={currentTradingVolume}
      targetVolume={targetVolume}
    />
  </>
  )
}