import { ethers } from 'ethers'
import axios from 'axios'

export const useCollectionLogic = (provider: ethers.providers.JsonRpcProvider, selectedChain: 'ETH' | 'BSC') => {
  const getGasPrice = async (): Promise<string> => {
    const gasPrice = await provider.getGasPrice()
    return ethers.utils.formatUnits(gasPrice, 'gwei')
  }

  // Get native token price from OKX
  const getNativeTokenPrice = async (): Promise<number> => {
    const symbol = selectedChain === 'ETH' ? 'ETH-USDT' : 'BNB-USDT'
    
    try {
      const response = await axios.get('https://www.okx.com/api/v5/market/ticker', {
        params: {
          instId: symbol
        }
      })
      
      // OKX returns price in the data[0].last field
      const price = parseFloat(response.data.data[0].last)
      return price
      
    } catch (error) {
      console.error('Failed to get native token price:', error)
      throw error
    }
  }

  const calculateGasCost = async (gasPrice: string, gasLimit: number): Promise<{ nativeCost: string; usdCost: string }> => {
    const gasCost = ethers.utils.parseUnits(gasPrice, 'gwei').mul(gasLimit)
    const nativeCost = ethers.utils.formatEther(gasCost)
    
    const nativeTokenPrice = await getNativeTokenPrice()
    const usdCost = (parseFloat(nativeCost) * nativeTokenPrice).toFixed(2)
    
    return { nativeCost, usdCost }
  }

  const transferTokens = async (
    from: string, 
    to: string, 
    amount: string, 
    privateKey: string, 
    tokenAddress: string | null,
    gasPrice: string,
    gasLimit: number,
    decimals: number,
    nonce: number
  ) => {
    const wallet = new ethers.Wallet(privateKey, provider)
    let tx
  
    const gasPriceWei = ethers.utils.parseUnits(gasPrice, 'gwei')
    const amountBigNumber = ethers.utils.parseUnits(amount, decimals)
  
    console.log(`Attempting to transfer ${amount} tokens (${amountBigNumber.toString()} in wei) from ${from} to ${to}`)
  
    const txOptions = {
      gasPrice: gasPriceWei,
      gasLimit,
      nonce 
    }
  
    if (tokenAddress) {
      const abi = ['function transfer(address to, uint256 amount) returns (bool)']
      const contract = new ethers.Contract(tokenAddress, abi, wallet)
      tx = await contract.transfer(to, amountBigNumber, txOptions)
    } else {
      tx = await wallet.sendTransaction({
        to,
        value: amountBigNumber,
        ...txOptions
      })
    }
  
    console.log(`Transaction hash: ${tx.hash}`)
    return tx
  }

  return {
    getGasPrice,
    calculateGasCost,
    transferTokens
  }
}