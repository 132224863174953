import React from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { ButtonPrimary } from '../Button'

const ModalContent = styled.div`
  padding: 20px;
  max-width: 600px;
  width: 90vw;

  @media (max-width: 480px) {
    padding: 15px;
    width: 95vw;
  }
`;

const ModalTitle = styled.h2`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.5rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.3rem;
    margin-bottom: 12px;
  }
`;

const TermsText = styled.p`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 0.9rem;
  line-height: 1.5;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.85rem;
    margin-bottom: 12px;
  }
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary1};
  font-size: 1.2rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.1rem;
    margin-top: 15px;
    margin-bottom: 8px;
  }
`;

const HighlightText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primary1};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`;

const AcceptButton = styled(ButtonPrimary)`
  width: 200px;

  @media (max-width: 480px) {
    width: 100%;
    max-width: 200px;
  }
`;

interface DisclaimerModalProps {
  isOpen: boolean;
  onAgree: () => void;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ isOpen, onAgree }) => {
  return (
    <Modal isOpen={isOpen} onDismiss={() => {}} minHeight={false} maxHeight={90}>
      <ModalContent>
        <ModalTitle>免责声明与使用说明</ModalTitle>

        <SectionTitle>本地运算与安全性</SectionTitle>
        <TermsText>
          <HighlightText>钱包生成完全在本地进行：</HighlightText>任何刷新或关闭浏览器都会导致私钥丢失，计算结果也无法通过网络传输，推导过程使用您的CPU进行处理。为了最大程度保障您的安全，我们强烈建议您在<HighlightText>断开网络连接情况下进行钱包生成操作</HighlightText>。
        </TermsText>
        <TermsText>
          <HighlightText>数据不会被上传或存储：</HighlightText>所有的运算都在您的设备上完成，生成的数据储存在您本地的React组件内存池中的浏览器中。所有生成的数据仅存在于您的本地设备中。
        </TermsText>

        <SectionTitle>免责声明</SectionTitle>
        <TermsText>
          <HighlightText>使用风险：</HighlightText>使用本工具生成的钱包时，请务必断网使用。切勿将生成的私钥或地址透露给任何人。
        </TermsText>
        <TermsText>
          <HighlightText>妥善保管：</HighlightText>请务必安全保管您生成的钱包信息，特别是私钥。一旦丢失，我们无法帮助您恢复。
        </TermsText>

        <ButtonContainer>
          <AcceptButton onClick={onAgree}>我已阅读并同意</AcceptButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}

export default DisclaimerModal