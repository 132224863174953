import React from 'react'
import styled from 'styled-components'
import { ExclamationCircle, CheckCircle } from 'react-bootstrap-icons'

const ToastContainer = styled.div<{ $isSuccess: boolean }>`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme, $isSuccess }) => $isSuccess ? theme.green1 : theme.red1};
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: auto;
  text-align: center;
`

const Icon = styled.div`
  flex-shrink: 0;
`

interface ToastProps {
  message: string
  isSuccess?: boolean
  onClose: () => void
}

const Toast: React.FC<ToastProps> = ({ message, isSuccess = false, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(onClose, 3000)
    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <ToastContainer $isSuccess={isSuccess}>
      <Icon>
        {isSuccess ? <CheckCircle size={20} /> : <ExclamationCircle size={20} />}
      </Icon>
      {message}
    </ToastContainer>
  )
}

export default Toast