// current token display

import React from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useSwapState } from '../../state/swap/hooks'

const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 12px;
  margin-right: 1rem;
`

const NetworkIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
`

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const TokenName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary1};
`

const NetworkName = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

export default function CurrentTokenDisplay() {
  const { chainId } = useActiveWeb3React()
  const { INPUT } = useSwapState()
  const inputCurrency = useCurrency(INPUT.currencyId)

  const getNetworkIcon = () => {
    if (chainId === 56 || chainId === 97) {
      return '/images/bsc.png'
    } else {
      return '/images/eth.png'
    }
  }

  const getNetworkName = () => {
    switch (chainId) {
      case 1:
        return 'Ethereum'
      case 56:
        return 'BSC'
      case 97:
        return 'BSC Testnet'
      case 11155111:
        return 'Sepolia'
      default:
        return 'Unknown Network'
    }
  }

  return (
    <DisplayContainer>
      <NetworkIcon src={getNetworkIcon()} alt={getNetworkName()} />
      <TokenInfo>
        <TokenName>{inputCurrency?.symbol || 'Select'}</TokenName>
        <NetworkName>{getNetworkName()}</NetworkName>
      </TokenInfo>
    </DisplayContainer>
  )
}